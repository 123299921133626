@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap);
/* ~~!!!~~~ FONT & COLOR VARIABLE ~~!!!~~~ */
/* GOOGLE Fonts Import */

:root {
  /* FONTS */
  --fontStyle1: 'Montserrat', sans-serif;
  
  /* THEME COLORS */
  /* --primaryColor: #D61A0C;
  --primaryColorAlpha: rgba(214, 26, 12, 0.05); */
  --primaryColor: #2998ff;
  --primaryColorAlpha: #f0f6fb;
  --primaryColorGB: linear-gradient(135deg,#2998ff,#7329ff);
  --secondaryColor: #858383;
  --tertiaryColor: #1b1b1b;

  /* DEFAULT COLORS */
  --whiteColor: #ffffff;
  --offWhiteColor: #f2f2f2;
  --dashBordBG: #fbfbfb;
  --blackColor: #000000;
  --grayColor: #cccccc;
  /* --darkBlue: #8B0000; */
  --darkBlue: #007ef3;
  --errorColor: #D2042D;
  --warningColor: #b78103;
  --warningColorAlpha: rgba(255, 193, 7, 0.16);
}

/* GLOBAL CSS */
body {
  margin: 0;
	font-weight: 400;
  font-family: 'Montserrat', sans-serif !important;
  font-family: var(--fontStyle1) !important;
}

form input {
	background: transparent;
  color: #1b1b1b;
  color: var(--tertiaryColor);
	border: 1px solid #cccccc;
	border: 1px solid var(--grayColor);
	outline: none;
	border-radius: 6px;
	font-size: 16px;
  margin: 0 0 6px;
  width: 100%;
  display: inline-block;
  outline: none;
  box-shadow: none;
  padding: 12px 18px;
}
form input:hover {
	border: 1px solid #1b1b1b;
	border: 1px solid var(--tertiaryColor);
}
form input:focus {
	border: 1px solid #1b1b1b;
	border: 1px solid var(--tertiaryColor);
}

/* Align CSS */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

/* Heading CSS ~ Global */
h1,h2,h3,h4,h5,h6 {
  margin-bottom: 15px !important;
  letter-spacing: 0.35px;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
a {
  color: #2998ff;
  color: var(--primaryColor);
  font-weight: 500;
	text-decoration: none;
}
.primaryColor {
  color: #2998ff !important;
  color: var(--primaryColor) !important;
}
.error-text {
  font-weight: 500;
  color: #D2042D !important;
  color: var(--errorColor) !important;
}

/* Margin CSS ~ Class */
.no-ml {
  margin-left: 0px !important;
}
.no-mr {
  margin-right: 0px !important;
}
.no-mb {
  margin-bottom: 0px !important;
}
.no-mt {
  margin-top: 0px !important;
}


/*  DASHBOARD CSS */
.main-dashboard-layout {
  padding: 0 20px 32px;
  width: 100%;
  height: calc(100vh - 66px);
  background: #fbfbfb !important;
  background: var(--dashBordBG) !important;
}
.card-body {
  margin: 30px 0 0;
  padding: 24px;
  border-radius: 8px;
  background-color: #ffffff;
  background-color: var(--whiteColor);
  box-shadow: 2px 2px 10px -6px #cccccc;
  box-shadow: 2px 2px 10px -6px var(--grayColor);
}
.card-title {
  font-weight: 700;
  color: #2998ff !important;
  color: var(--primaryColor) !important;
}
.card-title.shade {
  /* background-color: var(--primaryColorAlpha); */
  background: linear-gradient(135deg,#2998ff,#7329ff);
  background: var(--primaryColorGB);
  color: #ffffff !important;
  color: var(--whiteColor) !important;
  padding: 8px 16px;
  border-radius: 8px;
}
.card-title.icon {
  display: flex;
  align-items: center;
}
.card-title.icon svg {
  color: #2998ff;
  color: var(--primaryColor);
  margin: 0 8px 0 0;
  cursor: pointer;
  width: 28px;
}
.card-subtitle {
  font-size: 18px;
  font-weight: 600;
  color: #1b1b1b;
  color: var(--tertiaryColor);
  margin-bottom: 16px !important;
}

/* Site Button */
button.site-btn {
  cursor: pointer;
  display: inline-block;
  width: auto;
  height: 48px;
  max-width: 180px;
  padding: 4px 18px;
  border: 1px solid #2998ff;
  border: 1px solid var(--primaryColor);
  background-color: #2998ff;
  background-color: var(--primaryColor);
  color: #ffffff;
  color: var(--whiteColor);
  border-radius: 6px;
  transition: 0.4s all;
}
button.site-btn:hover {
  transition: 0.4s all;
  background-color: #007ef3;
  background-color: var(--darkBlue);
}
button.site-btn.rounded {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  max-width: 48px;
  width: 100%;
  padding: 6px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  line-height: 48px;
}
button.site-btn.rounded svg {
  width: 30px;
}
button.site-btn.icon {
  display: flex;
  align-items: center;
}
button.site-btn.icon svg {
  margin: 0 4px 0 0;
}


/* TAB's Card Body */
.card-tab-body {
  padding: 0;
  margin: 30px 0 0;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: none;
}
.card-tab-body .custom-tabs .ant-tabs-bar {
  margin: 0px;
  border: none;
}
.card-tab-body .custom-tabs .ant-tabs-content {
  background-color: #ffffff;
  background-color: var(--whiteColor);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 2px 2px 10px -6px #cccccc;
  box-shadow: 2px 2px 10px -6px var(--grayColor);
}
.card-tab-body .custom-tabs .ant-tabs-card .ant-tabs-tab {
  background: transparent !important;
  border: none !important;
  margin: 0 16px 0 0 !important;
  font-weight: 500;
}
.card-tab-body .custom-tabs .ant-tabs-card .ant-tabs-tab.ant-tabs-tab-active {
  background: #ffffff !important;
  background: var(--whiteColor) !important;
  box-shadow: inset -1px 2px 5px -3px rgba(0,0,0,0.2);
}

/* CUSTOM SCROLL */
.custom-scroll {
	overflow-y: auto;
  scrollbar-color: #2998ff #ffffff;
  scrollbar-color: var(--primaryColor) var(--whiteColor);
  scrollbar-width: thin;
}
.custom-scroll::-webkit-scrollbar {
  width: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #2998ff;
  background: var(--primaryColor);
  border-radius: 10px;
}
.page-login {
	display: flex;
	padding: 0 20px;
	align-items: center;
    justify-content: center;
	height: 100vh;
	overflow-y: auto;
	/* background: linear-gradient(240deg, var(--whiteColor), var(--offWhiteColor), var(--grayColor)); */
	background: linear-gradient(240deg, var(--primaryColor), var(--whiteColor));
	background-size: 200%;
	-webkit-animation: waves 5s ease infinite;
	        animation: waves 5s ease infinite;
}
/* Animate Background */
@-webkit-keyframes waves {
    0%{background-position:0% 25%}
    50%{background-position:100% 50%}
    100%{background-position:0% 25%}
}
@keyframes waves {
    0%{background-position:0% 25%}
    50%{background-position:100% 50%}
    100%{background-position:0% 25%}
}
.login-part{
    padding: 50px 35px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 500px;
	width: 100%;
	background-color: var(--whiteColor);
	/* box-shadow: 0px 1px 25px -10px var(--grayColor); */
	box-shadow: 0px 1px 25px -10px var(--whiteColor);
	border-radius: 8px;
}
.login-part .login-header {
    width: 100%;
    margin: 0 0 35px;
	text-align: center;
}
/* .page-login img.site-logo {
	max-width: 190px;
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
} */
.page-login img.site-logo {
	max-width: 315px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 90px;
}

/* LOGIN FORM  */
.loginForm {
	width: 100%;
}
.loginForm .input-field {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.loginForm .input-field label {
	display: block;
	font-weight: 400;
	width: 100%;
	font-size: 16px;
	margin: 0 0 8px;
	color: var(--tertiaryColor);
	text-transform: capitalize;
}
.loginForm p.validation-error {
    color: var(--errorColor);
    font-size: 14px;
	font-weight: 500;
	margin: 0;
}

.loginForm .submitBtn {
    cursor: pointer;
	position: relative;
    background: var(--primaryColor);
    color: var(--whiteColor);
    font-weight: 600;
    border-radius: 6px;
    font-size: 18px;
    width: 100%;
    margin: 25px 0 6px;
    letter-spacing: 0.5px;
	transition: 0.4s all;
}
.loginForm .submitBtn:hover {
    background: var(--darkBlue);
	transition: 0.4s all;
}
.loginForm .disableBtn {
    cursor: pointer;
	position: relative;
    background: var(--grayColor);
    color: var(--secondaryColor);
    font-weight: 600;
    border-radius: 6px;
    font-size: 18px;
    width: 100%;
    margin: 25px 0 6px;
    letter-spacing: 0.5px;
	transition: 0.4s all;
}
.login-part header.login-header .logo {
	max-width: 216px;
    height: 137px;
	margin-bottom: 0px;
    margin-top: 0px;
    background: transparent;
}
/* Search Header */
.search-rig-header {
  margin: 16px 0 24px;
}
.search-rig-header .search-btn-part {
  display: flex;
  flex-wrap: nowrap;
}
.search-rig-header .search-btn-part .export-btn {
  margin: 0 8px;
}
.search-rig-header .search-btn-part .addusers-btn {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.search-rig-header .search-btn-part .addusers-btn svg {
  width: 18px;
  height: 18px;
  margin: 0 4px 0 0;
}


.ant-dropdown-trigger.user_toggle.ant-dropdown-link.ant-dropdown-open {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.site-layout-background {
  background: #f2f2f2 !important;
}
.containText .ant-calendar-picker-clear, .containText .ant-calendar-picker-icon{
  right: 15px;
}
ul.ant-menu.menuSection.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-bottom: solid 1px gray;
}
/* animation of slider is not smooth */
/* ul.ant-menu-inline-collapsed{
  width: 100%;
} */
.header_user_details .header_user_name {
	margin-left: 12px;
	margin-right: 8px;
}
.sidebar_section .ant-menu .ant-menu-item .ant-menu-submenu .ant-menu-submenu-title{
  padding: 0 27px !important;
}

.totalRigDown {
  color: red !important;
  font-weight: bold;
}

.totalRigUp {
  font-weight: bold;
}
.mainClass {
    text-align: inherit !important;
    position: relative;
    clear: both;
    width: 100%;
}
.container{
	position: relative;
	width: 100%;
	max-width: 1170px;
	margin: 0 auto;
}
.rigInfo{
    background: gainsboro;
    padding: 10px 0;
    position: relative;
    color: #ea6863;
    margin: 1vw 0;
    border-radius: 8px;
}
/*rigHeader css*/
.site_header {
    background-color: #343f4b;
    color: #e87532;
    text-align: center;
    margin: 0 0 2vw 0;
    padding: 1vw 0;
    position: relative;
}
.rig_header h1{
	margin: 0;
}
/*rig Footer css*/
.site_footer{
	position: relative;
	bottom: 0;
	background-color: #343f4b;
    color: #e87532;
    text-align: center;
    width: 100%;
    padding: 1vw 0;
}
.p-last-child{
	margin-bottom: 0;
}
/*rigInfoSection-three css*/
.rigInfoSection-three{
	text-align: center;
	background-color: white;
}
.rigInfoSection-three .head-value{
	padding-bottom: 5px;
	font-size: 2.5rem;
	font-weight: bold;
	color: white;
}
.rigInfoSection-three .body-value{
	color: #FFF;
	font-size: 1.25rem;
}
.rigInfoSection-three .col-md-4{
	padding: 15px 0;
}
.rigInfoSection-three-inner{
	background-color: #343f4b;
    margin: 0 15px;
    padding: 15px;
    color: white
}
/*rigInfoSection-one css*/
.rigInfoSection-one{
	text-align: center;
}
.rigInfoSection-one p{
	font-weight: bold;
}
/*rigInfoSection-two css*/
.rigInfoSection-two{
	text-align: center;
}
.rigInfoSection-two .sky-blue{
	font-size: 20px;
	font-weight: bold;
}
.rigInfoSection-two .date{
	color: gray;
}
.relase-on{
	color: black;
}
/*rigInfoSection-open css*/
.rigInfoSection-open{
	padding: 10px 0;
	font-size: 18px;
}
.loader {
    margin: 17% 40%;
}
.create-report {
	margin-top: 7vh;
}
.rig-info-section {
	margin-bottom: 20px;
 }
.create-report-section {
	display: flex;
    justify-content: flex-end;
}
.create-report-tabs .ant-row .left-col {
	padding-right: 8px;
}
.create-report-tabs .ant-row .right-col {
	padding-left: 8px;
}
.theme-form .button-section-generate {
	justify-content: center;
	margin-top: 26px !important;
}
.row-details {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.row-details > .ant-col {
    padding: 0 8px;
}

/* Report Details ~ Header */
.report-header {
    padding: 16px 8px;
    background-color: var(--primaryColorAlpha);
    color: var(--primaryColor);
    margin: 0 0 32px;
}
.report-header h3 {
    color: var(--primaryColor);
    font-weight: 700;
}
.report-content .rig-logo {
    margin: 0 0 16px;
    text-align: center;
}
.report-content .rig-logo img {
    max-width: 300px;
    width: 100%;
}
.report-content .report-title-name {
    position: relative;
    font-weight: 600;
    padding: 0 0 16px;
    margin-bottom: 40px !important;
}
.report-content .report-title-name:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background: var(--primaryColor);
    margin: 0 auto;
}

/* Report Details ~ CopyRight */
.report-content .copy-right-content {
    margin: 32px 0 0;
    padding: 16px;
    font-size: 14px;
    background-color: var(--primaryColorAlpha);
    color: var(--tertiaryColor);
}
.report-content .copy-right-content p {
    margin: 0px;
    text-align: center;
}


.report-content .report-details {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.report-content .report-details .data-texts{
    word-break: break-all;
    width: 25%;
    padding: 0 10px;
    margin: 0 0 16px;
}
.report-content .report-details .data-texts b {
    display: block;
}
.report-content .report-details .data-texts .chips {
    padding: 4px 8px;
    background-color: var(--primaryColorAlpha);
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    margin: 6px 6px 0px 0;
    display: inline-block;
}


/* Report Details ~ Rig Pics */
.rig-pic-report .date-rig-pics {
    font-size: 16px;
    line-height: 1.4;
    color: var(--tertiaryColor);
    font-weight: 700;
    padding: 6px 12px;
    display: inline-block;
    margin-bottom: 8px !important;
    background: var(--primaryColorAlpha);
    border-radius: 6px;
}
.rig-pic-report .rig-pics-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.rig-pic-report .rig-box {
    width: 25%;
    padding: 0 10px;
    margin: 16px 0 0;
}
.rig-pic-report .rig-box .rig-box-inner .image-part {
    margin: 0 0 16px 0;
    box-shadow: 0px 0px 2px 1px rgba(145,158,171,0.16);
    border-radius: 6px;
}
.rig-pic-report .rig-box .rig-box-inner .image-part img {
    border-radius: 6px;
    width: 100%;
    height: 15.625VW; /* 300px */
    object-fit: cover;
}

.signature-blocks {
    margin: 0 0 16px 0;
}
.signature-blocks .rig-pics-row .rig-box {
    width: 100%;
}
.signature-blocks .rig-pics-row .rig-box .image-part-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
}
.signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part {
    width: calc(25% - 16px);
    margin: 0 8px 16px;
    overflow: hidden;
    position: relative;
}
.signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part span {
    position: absolute;
    top: 0;
    width: 100%;
    display: block;
    background: var(--darkBlue);
    color: var(--whiteColor);
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 5px;
}


/* Report Details ~ LINE HR */
hr.report-line {
    width: 100%;
    height: 0px;
    border: none;
    border-bottom: 1px solid var(--primaryColorAlpha);
    margin: 16px 0 32px;
}

/* Report Details ~ Rig Pics */
.details-part {
    display: flex;
    flex-wrap: wrap;
}
.small-details-box-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.small-details-box {
    width: calc(33.33% - 8px);
    background: var(--whiteColor);
    box-shadow: 0px 0px 6px -1px var(--grayColor);
    padding: 12px;
    font-size: 14px;
    border-radius: 4px;
    margin: 0 8px 16px;
}
.small-details-box p {
    margin: 0 0 6px;
    font-size: 14px;
}
.small-details-box p.date {
    font-weight: 700;
}
.small-details-box .chips-blog {
    display: inline-block;
    background-color: var(--primaryColorAlpha);
    color: var(--tertiaryColor);
    padding: 4px 6px;
    margin: 6px 6px 0 0;
    font-size: 12px;
    font-weight: 600;
}

/* Company Report */
.company-reports-box {
    width: 100%;
    background: var(--dashBordBG);
    box-shadow: 0px 0px 6px -1px var(--grayColor);
    padding: 12px;
    font-size: 14px;
    border-radius: 0px;
    margin: 0 0 16px 0;
}
.company-reports-box .table-company {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--whiteColor);
    width: 100%;
}
.company-reports-box .table-company .ant-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.company-reports-box .table-company .ant-row:nth-child(even) {
    background-color: var(--offWhiteColor);
}
.company-reports-box .table-company .ant-row .ant-col {
    width: 33.33%;
    padding: 8px;
}
.company-reports-box .table-company .ant-row.table-head {
    background: var(--primaryColorGB);
    color: var(--whiteColor);
}
.company-reports-box .table-company .ant-row .ant-col p {
    margin: 0px;
}
.company-reports-box .table-company .table-body {
    width: 100%;
}
.spacing-top {
    margin-top: 20px;
}
.spacing-bottom {
    margin-bottom: 20px;
}
.rig-down {
    color: red;
}
.rig-up {
    color: black;
}


/* Report Details ~ Responsive */
@media (max-width: 1440px) {
    .small-details-box {
        width: calc(50% - 8px);
    }
    .signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part,
    .rig-pic-report .rig-box {
        width: calc(33.33% - 16px);
    }
    .rig-pic-report .rig-box .rig-box-inner .image-part img {
        height: 19.4446VW; /* 280px */
    }
}
@media (max-width: 1280px) {
    .report-content .report-details .data-texts{
        width: 33.33%;
    }
}
@media (max-width: 1024px) {
    .report-content .report-details .data-texts{
        width: 50%;
    }
    .signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part,
    .rig-pic-report .rig-box {
        width: calc(50% - 16px);
    }
    .rig-pic-report .rig-box .rig-box-inner .image-part img {
        height: 24.4140625VW; /* 250px */
    }
    .small-details-box {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part,
    .rig-pic-report .rig-box {
        width: 100%;
    }
    .rig-pic-report .rig-box .rig-box-inner .image-part img {
        height: 52.17391304347826VW; /* 300px */
    }
    .report-content .report-details .data-texts{
        width: 100%;
    }
}
.rigImage-part {
    text-align: center;    
}
.rigImage-row {
    display: flex;
    flex-wrap: wrap;
    margin: 8px -12px 32px;
}
.rigImage-row .rigImage-box {
    width: calc(20% - 24px);
    cursor: pointer;
    /* padding: 16px; */
    background-color: var(--whiteColor);
    box-shadow: 0px 0px 2px 1px rgba(145,158,171,0.16);
    margin: 24px 12px 0;
    border-radius: 6px;
    transition: 0.4s all;
}
.rigImage-row .rigImage-box .inner-area {
    padding: 16px;
}
.rigImage-row .rigImage-box img.rig-photo {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    margin: 0 0 12px;
}
.rigImage-row .rigImage-box h4 {
    font-size: 16px;
    font-weight: 600;
    color: var(--tertiaryColor);
    margin-bottom: 0 !important;
    transition: 0.4s all;
}
.rigImage-row .rigImage-box p {
    margin: 0px;
    color: var(--secondaryColor);
    font-size: 14px;
}
.rigImage-row .rigImage-box:hover h4 {
    color: var(--primaryColor);
    transition: 0.4s all;
}
.rigImage-row .rigImage-box:hover {
    box-shadow: rgba(145,158,171,0.16) 0px 16px 32px -4px;
    transition: 0.4s all;
}


@media (max-width: 1440px) {
    .rigImage-row .rigImage-box {
        width: calc(25% - 24px);
    }
}
@media (max-width: 1280px) {
    .rigImage-row .rigImage-box img.rig-photo {
        height: 170px;
    }
}
@media (max-width: 1024px) {
    .rigImage-row .rigImage-box {
        width: calc(33.33% - 24px);
    }
}
@media (max-width: 991px) {
    .rigImage-row .rigImage-box {
        width: calc(50% - 24px);
    }
}



.card-title-error {
    font-weight: 600;
    color: var(--errorColor);
    text-align: center;
}
.warning-headline {
    display: flex;
    align-items: center;
    background-color: var(--warningColorAlpha);
    color: var(--warningColor);
    border-radius: 6px;
    margin: 24px 0;
    padding: 16px;
}
.warning-headline h4{
    color: var(--warningColor);
    font-weight: 600;
    padding-left: 8px;
}

/* NOT USED FULL CSS */
.jsa_table{
    margin: 3% 30%;
    width: 45%;
}
.jsa_tr {
    margin-bottom: 3%;
    font-size: 16px;
}
.jsa_details {
    border-bottom: 2px solid #000;
    font-size: 15px;
    font-weight: 700;
    display: block;
    margin-bottom: 5%;
    margin-right: -20%;
}
.jsa_image {
    padding-right: 30px;
    width: 250px;
    height: 300px;    
}
.jsa_image_tr{
    font-weight: 700;
    display: block;
    margin-right: -40%;
}
.declaration {
    font-size: 18px;
    font-weight: 500;
    background-color: bisque;
    border-radius: 5px;
    padding: 2% 2% 2% 5%;
    margin-right: -3%;
    width: 85%;
}

.customed-avatar {
    display: flex;
    border: 0px;
}
.customed-avatar .avatar-table-part {
    display: flex;
}
.customed-avatar .avatar-table {
    border: 2px solid var(--whiteColor);
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36px;
    background: var(--primaryColor);
    color: var(--whiteColor);
}
.avatar-table-part .avatar-table:nth-child(3), .avatar-table-part .avatar-table:nth-child(2) {
    margin: 0 0px 0 -14px;
}
.customed-avatar .avatar-table.number {
    background: var(--tertiaryColor);
    margin: 0 0 0 -14px;
}
.groups-icon-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 0 0;
}
.groups-icon-title i {
    font-size: 26px;
    margin: 0 8px 0 0;
    color: var(--primaryColor);
}

/* Sticky Groups body */
.sticky-accordion-part {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 11;
    padding: 16px 0 20px;
    margin: 0 0 16px 0;
    border-bottom: 1px solid var(--primaryColorAlpha);
    background: var(--whiteColor);
}
.sticky-accordion-part .site-btn.transparent {
    margin: 12px 0 0;
    padding: 0;
    height: auto;
    background: transparent;
    color: var(--primaryColor);
    border: transparent;
    font-weight: 600;
    font-size: 16px;
}
.sticky-accordion-part .site-btn.transparent:hover {
    color: var(--darkBlue);
}

/* Accordion */
.accordion-custom {
    border: 1px solid var(--primaryColorAlpha);
    padding: 0;
    margin: 0 0 32px;
}
.accordion-custom .accordion-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 !important;
    padding: 16px;
    border-left: 6px solid var(--primaryColor) !important;
    color: var(--tertiaryColor);
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.accordion-custom .accordion-head .group-right-chips {
    background-color: var(--primaryColorAlpha);
    color: var(--secondaryColor);
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 12px;
    line-height: 1;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}
.accordion-custom .accordion-head .group-right-chips i {
    margin: 0 4px 0 0;
    font-size: 14px;
}
.accordion-custom .accordion-head .group-right-chips span {
    font-weight: 700;
    margin: 0 4px 0 0;
}
.accordion-custom .accordion-head .dragIcon {
    cursor: grabbing;
    width: 12px;
    margin: 0 6px 2px 0;
    -webkit-user-select: none;
            user-select: none;
}
.accordion-custom .accordion-head .deletIcon {
    margin: 0 0 0 6px;
    display: inline-block;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 0;
    transition: 0.3s all;
}
.accordion-custom .accordion-head:hover .deletIcon {
    opacity: 1;
    transition: 0.3s all;
    -webkit-transform: translateX(6px);
            transform: translateX(6px);
}
.accordion-custom .accordion-head .deletIcon:hover {
    opacity: 0.7;
    transition: 0.3s all;
    -webkit-transform: translateX(6px) scale(0.9);
            transform: translateX(6px) scale(0.9);
}
.accordion-custom .accordion-head .left-part {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.accordion-custom .accordion-head .left-part .drag-input{
    display: flex;
    align-items: center;
}

/* Active Head */
.accordion-custom.active .accordion-head {
    background-color: var(--dashBordBG);
}
/* Accordion content */
.accordion-custom .accordion-content {
    margin: 0 0 0;
    padding: 16px 16px 8px;
    border-top: 1px solid var(--primaryColorAlpha);
}
.accordion-custom .accordion-content .add-group-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 6px 0 4px;
}
.accordion-custom .accordion-content .add-group-form input.group-input{
    border: 1px solid var(--grayColor);
    color: var(--tertiaryColor);
    box-shadow: none;
    border-radius: 4px;
    height: 44px;
    font-weight: 400;
    margin: 0 0 6px 0;
    width: 50%;
}
.accordion-custom .accordion-content .add-group-form input.group-input:focus {
    border-color: var(--primaryColor);
}
.accordion-custom .accordion-content .add-group-form .select-option-part {
    width: calc(50% - 136px);
}
.accordion-custom .accordion-content .add-group-form .select-option-part p.error {
    color: var(--errorColor);
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple {
    width: 100%;
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple .ant-select-selection {
    box-shadow: none;
    padding: 2px 0 0 0;
    font-size: 12px;
    min-height: 44px;
    height: 44px;
    overflow: auto;
    border-color: var(--grayColor) !important;
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple .ant-select-selection:hover {
    border-color: var(--primaryColor) !important;
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple .ant-select-selection ul .ant-select-selection__choice{
    border: none;
    font-size: 12px;
    font-weight: 500;
    color: var(--primaryColor);
    background-color: var(--primaryColorAlpha);
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple .ant-select-selection ul .ant-select-selection__choice i {
    color: var(--tertiaryColor);
}
.accordion-custom .accordion-content .add-group-form .submitBtn {
    height: 44px;
    font-size: 14px;
    font-weight: 500;
}


.addSegment-accordion .accordion-head .left-part {
    display: flex;
    align-items: center;
}
.addSegment-accordion .accordion-head input.add-rename-input {
    border: 1px dashed var(--primaryColor);
    margin: 0 0 0 6px;
    box-shadow: none;
    color: var(--tertiaryColor);
    font-weight: 600;
}


.accordion-custom .avatar-table-part {
    display: flex;
}
.accordion-custom .avatar-table {
    border: 2px solid var(--whiteColor);
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36px;
    background: var(--primaryColor);
    color: var(--whiteColor);
}
.avatar-table-part .avatar-table:nth-child(3), .avatar-table-part .avatar-table:nth-child(2) {
    margin: 0 0px 0 -14px;
}
.accordion-custom .avatar-table.number {
    background: var(--tertiaryColor);
    margin: 0 0 0 -14px;
}

.group-name-txt {
    display: flex;
}

.group-name-txt span {
    opacity: 0.0;
    transition: all 400ms ease-in-out;
    justify-content: center;
    margin-left: 5px;
}
.group-name-txt:hover span {
    opacity: 0.3;
    display: inline-block;
}
.site-header {
  position: relative;
  z-index: 999;
  background: var(--whiteColor);
  height: 68px;
  padding: 0;
  box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.10);
}
.site-header .inner-area {
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.site-header .inner-area > .ant-row {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.site-header .site-logo {
  max-width: 200px;
  width: 100%;
}
.site-header .left-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.site-header .right-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Search Input */
.site-header .header-search-input {
  padding: 0 0 0 30px;
  max-width: 300px;
  transition: 0.6s all;
}
/* .site-header .header-search-input:focus,
.site-header .header-search-input:hover {
  transition: 0.6s all;
  max-width: 100%;
} */
.site-header .header-search-input input {
  padding: 6px 14px;
  font-size: 14px;
  height: 44px;
	background: transparent;
  color: var(--tertiaryColor);
  box-shadow: none;
	border: 1px solid var(--grayColor);
	border-color: var(--grayColor) !important;
}
.site-header .header-search-input input:hover {
	border: 1px solid;
	border-color: var(--tertiaryColor) !important;
}
.site-header .header-search-input input:focus {
	border: 1px solid;
	border-color: var(--tertiaryColor) !important;
}
.site-header .header-search-input .anticon-search svg {
  width: 18px;
  height: 22px;
  fill: var(--secondaryColor);
}

/* Notification Bell */
.site-header .notification-bell {
  color: var(--secondaryColor);
  transition: 0.4s all;
  cursor: pointer;
  margin: 0 16px 0 0;
}
.site-header .notification-bell:hover {
  color: var(--primaryColor);
  transition: 0.4s all;
}

/* User Dropdown */
.site-header .user-profile-dropdown {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.site-header .user-profile-dropdown .avatar-circle {
  width: 44px;
  height: 44px;
  font-size: 16px;
  line-height: 46px;
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  border-radius: 50%;
  transition: 0.5s all;
}
.site-header .user-profile-dropdown .avatar-circle:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.5s all;
  background-color: var(--darkBlue);
}
.site-header .user-profile-dropdown.ant-dropdown-open .avatar-circle {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.5s all;
  background-color: var(--darkBlue);
}
.site-header .user-profile-dropdown .user-name {
  height: 20px;
  line-height: 1.6;
}
.site-header .user-profile-dropdown .user-name span {
  line-height: 1.6;
  font-size: 14px;
  padding: 0 0 0 8px;
  color: var(--secondaryColor);
}
/* User Dropdown ~ Box */
.dropdown-boxuser {
  min-width: 190px;
  max-width: 190px;
  padding: 12px 8px;
  margin: 8px 0 0;
  background-color: var(--whiteColor);
  border-radius: 4px;
  box-shadow: rgba(0,0,0,0.1) 0px 0px 2px 0px, rgba(0,0,0,0.1) -20px 20px 40px -4px;
}
.dropdown-boxuser .user-name {
  border-bottom: 1px dashed var(--grayColor);
  margin: 0 0 8px;
}
.dropdown-boxuser .user-name p {
  color: var(--tertiaryColor);
  padding: 4px 10px;
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-boxuser .user-menus {
  border: none;
}
.dropdown-boxuser .user-menus .menu-item {
  height: auto;
  line-height: 1.6;
  font-weight: 400;
  padding: 4px 12px;
  font-size: 14px;
  color: var(--tertiaryColor);
  transition: 0.5s all;
}
.dropdown-boxuser .user-menus .menu-item:hover {
  transition: 0.5s all;
  background-color: var(--primaryColorAlpha);
}
.dropdown-boxuser .user-menus .menu-item.logout-menu {
  color: var(--errorColor);
  font-weight: 600;
}


/* SIDEBAR ~ CSS */
.sidebar-part {
  background-color: var(--whiteColor);
  height: calc(100vh - 66px);
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 0px 12px -5px var(--grayColor);
}
.sidebar-part .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-part .sidebar-toggle-part .sidebar-toggle-btn {
  cursor: pointer;
  background-color: transparent;
  padding: 6px 0 0;
  border-top: 1px dashed var(--grayColor);
  border-radius: 0;
}
.sidebar-part .sidebar-toggle-part .sidebar-toggle-btn svg {
  width: 20px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: 0.3s all;
  color: var(--secondaryColor);
}
.sidebar-part .sidebar-toggle-part.sidebar-small .sidebar-toggle-btn svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: 0.3s all;
}

/* Sidebar Menu */
.sidebar-part .sidebar-menu {
  margin: 30px 0 0;
  border: none;
}
.sidebar-part .sidebar-menu .ant-menu-item {
  padding: 10px 28px;
  margin: 0 0 6px;
  height: auto;
}
.sidebar-part .sidebar-menu .ant-menu-item a {
  display: flex;
  height: auto;
  align-items: center;
  line-height: 1.6;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: var(--secondaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-item a i {
  color: var(--secondaryColor);
  line-height: 0;
}
.sidebar-part .sidebar-menu .ant-menu-item a i svg {
  width: 18px;
  height: 18px;
}
.sidebar-part .sidebar-menu .ant-menu-item:hover {
  background-color: var(--offWhiteColor);
}
.sidebar-part .sidebar-menu .ant-menu-item.ant-menu-item-selected {
  background-color: var(--primaryColorAlpha);
}
.sidebar-part .sidebar-menu .ant-menu-item.ant-menu-item-selected a i {
  color: var(--primaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-item.ant-menu-item-selected a {
  color: var(--primaryColor);
}
/* --Submenu */
.sidebar-part .sidebar-menu .ant-menu-submenu {
  padding: 10px 5px 10px 28px;
  margin: 0 0 6px;
  height: auto;
}
.sidebar-part .sidebar-menu .ant-menu-submenu:hover {
  background-color: var(--offWhiteColor);
}
.sidebar-part .sidebar-menu .ant-menu-submenu .ant-menu-submenu-title {
  height: auto;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}
.sidebar-part .sidebar-menu .ant-menu-submenu .ant-menu-submenu-title span {
  display: flex;
  align-items: center;
  color: var(--secondaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-submenu.ant-menu-submenu-selected {
  background-color: var(--primaryColorAlpha);
}
.sidebar-part .sidebar-menu .ant-menu-submenu.ant-menu-submenu-selected:hover span {
  color: var(--primaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-submenu.ant-menu-submenu-selected span {
  color: var(--primaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-submenu .ant-menu-submenu-title span svg {
  width: 18px;
  height: 18px;
}
.sidebar-part .sidebar-menu .ant-menu-submenu:hover .ant-menu-submenu-title span + i::after,
.sidebar-part .sidebar-menu .ant-menu-submenu:hover .ant-menu-submenu-title span + i::before {
  background: linear-gradient(to right, var(--primaryColor), var(--primaryColor));
}
/* Global --Submenu CSS */
.ant-menu-submenu .ant-menu-sub .ant-menu-item {
  margin: 0;
}
.ant-menu-submenu .ant-menu-sub .ant-menu-item:hover a {
  color: var(--primaryColor);
}
.ant-menu-submenu .ant-menu-sub .ant-menu-item.ant-menu-item-selected {
  background-color: var(--primaryColorAlpha);
}
.ant-menu-submenu .ant-menu-sub .ant-menu-item.ant-menu-item-selected a {
  color: var(--primaryColor);
}
/* --Collapsed */
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed .ant-menu-item {
  padding: 8px 20px !important;
}
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed .ant-menu-item a {
  justify-content: center;
}
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed  .ant-menu-submenu {
  padding: 8px 5px 4px 28px;
  margin: 0 0 6px;
  height: auto;
}
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed  .ant-menu-submenu .ant-menu-submenu-title span i {
  line-height: 1;
}
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0 !important;
}

/* Quick Action - Section */
.user-action-part {
    display: flex;
    flex-wrap: wrap;
    margin: 16px -8px 0;
}
.user-action-part .user-boxes {
    width: 33.33%;
    padding: 0 8px;
    margin: 0 0 16px;
}
.user-action-part .user-boxes a {
    display: inline-flex;
    border: 1px solid var(--grayColor);
    background-color: var(--whiteColor);
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 22px;
    width: 100%;
    transition: 0.5s all;
}
.user-action-part .user-boxes a img {
    max-width: 60px;
    margin: 0 0 8px;
}
.user-action-part .user-boxes a p {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: var(--tertiaryColor);
}
.user-action-part .user-boxes a:hover {
    transition: 0.5s all;
    background-color: var(--primaryColorAlpha);
    border-color: var(--darkBlue);
}

/* Rigs info - Section */
.rigs-info-block {
    margin: 24px 0 0 0;
}
.rigs-card-part .small-title {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 6px;
    color: var(--tertiaryColor);
    width: 100%;
    display: block;
}
.rigs-card-part .riginfo-boxes {
    padding: 14px;
}
.rigs-card-part .riginfo-boxes.border-r-dash {
    border-right: 1px dashed var(--grayColor);
}
.rigs-card-part .riginfo-boxes .riginfo-details {
    margin: 0 0 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 8px;
    border-bottom: 1px solid var(--offWhiteColor);
}
.rigs-card-part .riginfo-boxes .riginfo-details:last-child {
    border: none;
}
.rigs-card-part .riginfo-boxes .riginfo-details span {
    position: relative;
    display: block;
    padding-left: 16px;
    margin-left: 10px;
}
.rigs-card-part .riginfo-boxes .riginfo-details span:before {
    content: '';
    position: absolute;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 8px;
    background-color: var(--grayColor);
}
.rigs-card-part .riginfo-boxes .riginfo-details .name {
    font-weight: 600;
    font-size: 14px;
    color: var(--primaryColor);
}
.rigs-card-part .riginfo-boxes .riginfo-details .number {
    font-weight: 600;
    font-size: 14px;
    color: var(--tertiaryColor);
}

/* Date Picker */
.custom-date-picker {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 8px;
}
.custom-date-picker .date_picker {
    margin: 0px;
    width: 100%;
}
.custom-date-picker .date_picker input {
    border-radius: 0px;
    display: flex;
    color: var(--tertiaryColor);
    border: none;
    padding: 22px 10px;
    box-shadow: 0 0px 8px -2px var(--grayColor);
}
.custom-date-picker .date_picker .ant-calendar-picker-icon {
    color: var(--tertiaryColor);
}
#DropdownFixPostion .ant-calendar-input {
    border: none !important;
    padding-left: 0;
    font-size: 14px;
}


/* Report Details Card */
.report-details-card .small-title {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 6px;
    color: var(--tertiaryColor);
    width: 100%;
    display: block;
}
.most-recent-rig-cards {
    margin: 24px 0 0 0;
}
.most-recent-rig-cards .ant-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.most-recent-rig-cards .boxes {
    width: calc(25% - 16px);
    padding: 16px;
    border-radius: 8px;
    margin: 16px 8px 0;
    background: var(--primaryColorAlpha);
    /* box-shadow: 0px 0px 6px -2px var(--grayColor); */
}
.most-recent-rig-cards .boxes h5 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
}
.most-recent-rig-cards .boxes p {
    margin: 6px 0 0;
}
.most-recent-rig-cards .boxes p span {
    font-weight: 500;
    color: var(--tertiaryColor);
}
.custom-search {
    width: '100%'
}


@media (max-width: 1440px) {
    .most-recent-rig-cards .boxes {
        width: calc(33.33% - 16px);
    }
}
@media (max-width: 991px) {
    .most-recent-rig-cards .boxes {
        width: calc(50% - 16px);
    }
    .rigs-card-part .riginfo-boxes {
        width: 100%;
        border-bottom: 1px dashed var(--grayColor);
        border-right: none;
        margin: 0 0 8px;
    }
    .rigs-card-part .riginfo-boxes.border-r-dash {
        border-right: none;
    }
}
/* Filter Table CSS */
.filter-table-area {
    position: absolute;
    left: 0;
    z-index: 2;
    width: 100%;
    max-width: 240px;
}
.filter-table-area .filter-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 48px;
    height: 48px;
    background-color: var(--whiteColor);
    box-shadow: 1px 1px 14px 2px var(--offWhiteColor);
    margin: 0 0 8px;
    border-radius: 6px;
}
.filter-table-area .filter-btn svg {
    font-size: 26px;
}
.filter-table-area .filter-btn.active {
    background-color: var(--primaryColor);
}
.filter-table-area .filter-btn.active svg {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}
.filter-column-box {
    position: relative;
    top: -56px;
    left: 56px;
    -webkit-user-select: none;
            user-select: none;
}
.filter-column-box ul {
    border-radius: 4px;
    border: none;
    height: 300px;
    overflow: auto;
    box-shadow: 1px 2px 20px 0px var(--offWhiteColor) !important;
    scrollbar-color: var(--primaryColor) var(--whiteColor);
    scrollbar-width: thin;
}
.filter-column-box ul li {
    height: auto !important;
    padding: 0 12px !important;
    margin: 0px !important;
    text-transform: capitalize;
    color: var(--secondaryColor);
    border-bottom: 1px solid var(--offWhiteColor);
}
.filter-column-box ul li:hover {
    color: var(--primaryColor);
}
.filter-column-box ul li.ant-menu-item-selected {
    color: var(--tertiaryColor);
    background-color: var(--primaryColorAlpha) !important;
    border-bottom: 1px solid var(--primaryColorAlpha);
}
.filter-column-box ul li:last-child {
    border: none;
}
/* Scrollbar css */
.filter-column-box ul::-webkit-scrollbar {
    width: 5px;
}
.filter-column-box ul::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}
.filter-column-box ul::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}
/* Untitled SECTION */
.untitled-title {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
}
.untitled-title svg {
    color: var(--primaryColor);
    font-size: 30px;
    margin: 0px 5px 0px 0px;
}
.unpublish-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 8px;
    background-color: var(--primaryColorAlpha);
    border-radius: 6px;
	margin: 0 0 16px 0;
}
.unpublish-box .content {
    padding: 0 12px 0 0;
    width: 100%;
}
.unpublish-box .content h6 {
	display: flex;
	align-items: center;
	color: var(--primaryColor);
	font-weight: 600;
}
.unpublish-box .content h6 svg {
	margin: 0 6px 0 0;
    font-size: 22px;
}
.unpublish-box .content p {
	color: var(--secondaryColor);
}


/* Create Object ~ Modal */
.object-box-part {
    display: flex;
    flex-wrap: wrap;
}
.send-mail-modal .object-box-part .object-boxes {
    width: 20% !important;
	transition: 0.4s all;
	background-color: var(--whiteColor);
    text-align: center;
	border-radius: 4px;
}
.send-mail-modal.modal-footer-hide .ant-modal-footer {
	display: none;
}
.object-box-part .object-boxes .inner-area {
    padding: 16px 8px 10px;
	cursor: pointer;
}
.object-box-part .object-boxes .inner-area svg {
	transition: 0.4s all;
	margin: 0 0 6px;
    font-size: 32px;
	/* color: var(--tertiaryColor); */
	color: var(--secondaryColor);
}
.object-box-part .object-boxes .inner-area p {
	color: var(--tertiaryColor);
	font-weight: 500;
	transition: 0.4s all;
	margin: 0px;
}
.object-box-part .object-boxes .select-disabled {
	cursor: auto;
}
.object-box-part .object-boxes:hover {
	transition: 0.4s all;
	background-color: var(--primaryColorAlpha);
}
.object-box-part .object-boxes:hover svg {
	transition: 0.4s all;
	color: var(--primaryColor);
}
.object-box-part .object-boxes:hover p {
	transition: 0.4s all;
	color: var(--primaryColor);
}

/*  */
.layout-box-part {
    display: flex;
    flex-wrap: wrap;
}
.send-mail-modal .layout-box-part .layout-box {
    width: 50% !important;
	transition: 0.4s all;
	background-color: var(--whiteColor);
    text-align: center;
	border-radius: 4px;
}
.layout-box-part .layout-box .inner-area {
    padding: 16px 8px 10px;
	cursor: pointer;
}
.layout-box-part .layout-box .inner-area svg {
	margin: 0 0 6px;
	font-size: 72px;
	color: var(--secondaryColor);
	transition: 0.4s all;
}
.layout-box-part .layout-box .inner-area p {
	margin: 0px;
	font-weight: 500;
	color: var(--tertiaryColor);
	transition: 0.4s all;
}
.send-mail-modal .layout-box-part .layout-box:hover {
	background-color: var(--primaryColorAlpha);
	transition: 0.4s all;
}
.send-mail-modal .layout-box-part .layout-box:hover p,
.send-mail-modal .layout-box-part .layout-box:hover svg {
	transition: 0.4s all;
	color: var(--primaryColor);
}


/* SAFTY FORM */
.safetyform-card-body {
	height: calc(100vh - 190px);
    padding: 0 0 0 24px;
}
.safetyform-field-part {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
    justify-content: space-between;
}
.add-field-area {
    width: 100%;
    max-width: 375px;
    padding: 24px 0;
}

.field-area {
    padding: 24px 16px;
	width: 100%;
	max-width: calc(100% - 728px);
    height: 100%;
    overflow-y: auto;
    /* overflow-y: unset; */
}

.iphone-view-area {
    padding: 50px 0 0;
	width: 100%;
	max-width: 350px;
	height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    background-color: var(--primaryColorAlpha);
}
.iphone-view-area .iphone-body {
	/* width: 100%;
	max-width: 100%; */
    width: 350px;
	-webkit-user-select: none;
	        user-select: none;
}
.safetyform-field-part .description-btn {
	background-color: transparent;
	border-color: var(--primaryColor);
	color: var(--primaryColor);
	display: table;
	margin: 0 auto;
	max-width: 200px;
	width: 100%;
	height: 44px;
	border-radius: 40px;
	cursor: pointer;
	transition: 0.4s all;
}
.safetyform-field-part .description-btn:hover {
	color: var(--whiteColor);
}
.safetyform-field-part hr.devider {
    margin: 24px 0;
    height: 0px;
    border: 1px solid var(--primaryColorAlpha);
}
.safetyform-field-part .fields-area {
	max-height: calc(100vh - 500px);
}
.safetyform-field-part .safetyform-fields {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.safetyform-field-part .safetyform-fields .template-btn {
    margin: 0 0 16px;
    width: calc(50% - 5px);
	color: var(--tertiaryColor);
    background: var(--primaryColorAlpha);
    font-size: 14px;
    border-radius: 50px;
    padding: 8px 16px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
	transition: 0.4s all;
	display: flex;
	align-items: center;
	justify-content: center;
}
.safetyform-field-part .safetyform-fields .template-btn svg,
.safetyform-field-part .safetyform-fields .template-btn i {
	margin: 0 2px 0 0;
}
.safetyform-field-part .safetyform-fields .template-btn:hover {
	transition: 0.4s all;
	background-color: var(--primaryColor);
	color: var(--whiteColor);
}
.safetyform-field-part .show-more-btn {
	background-color: transparent;
	border-color: var(--primaryColor);
	color: var(--primaryColor);
	letter-spacing: 0.5px;
	display: table;
	margin: 12px auto 0;
	font-weight: 500;
	max-width: 100%;
	width: 100%;
	height: 44px;
	border-radius: 40px;
	cursor: pointer;
	transition: 0.4s all;
}
.safetyform-field-part .show-more-btn:hover {
	color: var(--whiteColor);
}
.safetyform-field-part .bottom-btn {
	display: flex;
	justify-content: space-between;
}
.safetyform-field-part .bottom-btn button {
    width: calc(50% - 8px);
    margin: 16px 0 0;
}
.safetyform-field-part .bottom-btn button.solid {
	color: var(--whiteColor);
	background-color: var(--primaryColor);
}
.safetyform-field-part .bottom-btn button.solid:hover {
	background-color: var(--darkBlue);
}


/* Field Lists */
.field-area .no-field-lists {
	display: flex;
	flex-wrap: wrap;
    align-items: center;
    justify-content: center;
	text-align: center;
    flex-direction: column;
    height: 100%;
}
.field-area .field-lists {
	position: relative;
	display: flex;
    flex-wrap: wrap;
    align-items: center;
	transition: 0.4s all;
	/* width: calc(100% - 20px); */
	width: calc(100% - 50px);
    /* padding: 12px 16px; */
    padding: 12px 16px 12px 28px;
    margin: 0 0 16px 0;
    background-color: var(--whiteColor);
    box-shadow: 0px 0px 2px 1px rgba(145,158,171,0.16);
    border-radius: 4px;
    cursor: pointer;
}
.field-area .field-lists:hover {
	box-shadow: rgba(145,158,171,0.16) 0px 16px 32px -4px;
	transition: 0.4s all;
}
/* .field-area .field-lists .field-index { */
.field-area .field-index {
    display: flex;
    align-items: center;
    justify-content: center;
	border: 1px solid var(--grayColor);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 12px;
	font-weight: 500;
    margin: 0 16px 12px 0;
	cursor: grabbing;
}
.field-area .field-lists .field-content {
	margin: 0px;
    width: calc(100% - 110px);
}
.field-area .field-lists .field-content .title-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    color: var(--tertiaryColor);
}
.field-area .field-lists .field-content .subtexts {
	font-size: 12px;
    color: var(--secondaryColor);
    margin: 0;
    display: inline-block;
    border-radius: 14px;
    font-weight: 500;
	text-transform: capitalize;
}
.field-area .field-lists .copy-delete-icons {
    display: flex;
    align-items: center;
    width: 60px;
    opacity: 0;
}
.field-area .field-lists .copy-delete-icons .copy-icon {
	color: black;
}
.field-area .field-lists .copy-delete-icons button {
	padding: 0;
    background-color: transparent;
    border: none;
    height: auto;
    font-size: 18px;
	cursor: pointer;
	transition: 0.4s all;
}
.field-area .field-lists .copy-delete-icons button:hover i {
	transition: 0.4s all;
	-webkit-transform: scale(0.9);
	        transform: scale(0.9);
}
.field-area .field-lists:hover .copy-delete-icons {
	transition: 0.4s all;
	opacity: 1;
}
.field-area .field-lists:hover img.drag-dots {
	transition: 0.4s all;
	opacity: 1;
    left: 14px;
}



/* Safty Form ~ MODAL */
.form-export-part {
    margin: 0 0 18px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.form-export-part .form-export {
	display: flex;
}
.form-export-part .form-export p {
	padding: 0 0 0 8px;
    margin: 0 0 0 8px;
	cursor: pointer;
	color: var(--secondaryColor);
    border-left: 1px solid var(--secondaryColor);
	transition: 0.3s all;
}
.form-export-part .form-export p:first-child {
	border-left: none;
}
.form-export-part .form-export p:hover {
	transition: 0.3s all;
	color: var(--primaryColor);
}

.drag-items {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
    justify-content: space-between;
    /* background-color: var(--whiteColor); */
}
.drag-items img.drag-dots {
    position: absolute;
	width: 100%;
    max-width: 12px;
    left: 0px;
	opacity: 0;
	transition: 0.3s all;
	cursor: grab;
    /* margin: 0 0 10px; */
}
.drag-items .field-icons {
    font-size: 20px;
    margin: 0 10px 0 6px;
}
.drag-items .ant-form-item {
    /* width: calc(100% - 40px); */
	width: calc(100% - 14px);
	margin-bottom: 0 !important;
    padding: 0 0 0 8px !important;
}
.drag-items .ant-form-item input {
	background-color: var(--whiteColor) !important;
}
.drag-items.dropdown-drag {
	margin: 0 0 12px 0;
}
.drag-items.dropdown-drag img.drag-dots {
	position: relative;
	opacity: 1;
    margin: 30px 0 0;
}
button.site-btn.add-field {
    width: 100%;
    max-width: 118px;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    padding: 4px;
}
.field-hr-divider {
	margin: 24px 0;
    border: none;
    border-bottom: 1px solid var(--primaryColorAlpha);
}
.field-checkbox {
    display: block;
    margin: 8px 0 0;
}
.field-range-slider {
	display: flex;
	flex-wrap: wrap;
    align-items: center;
}
.field-range-slider .devider-text {
	padding: 0 12px;
}

/* Responsive */
@media (max-width: 1440px) {
	.safetyform-card-body {
		padding: 0 0 0 12px;
	}
	.field-area .field-lists {
		padding: 12px 10px 12px 28px;
		margin: 0 0 12px 0;
	}
	.add-field-area {
		max-width: 330px;
	}
	.field-area {
		padding: 24px 14px;
		max-width: calc(100% - 682px);
	}
	.safetyform-field-part .safetyform-fields .template-btn {
		padding: 8px 14px;
		margin: 0 0 10px;
		font-size: 12px;
		width: calc(50% - 4px);
	}
}
@media (max-width: 1280px) {}
@media (max-width: 1199px) {
	.add-field-area {
		max-width: 200px;
	}
	.field-area {
		max-width: calc(100% - 552px);
	}
	.safetyform-field-part .description-btn {
		font-size: 14px;
		font-weight: 600;
		height: 40px;
		max-width: 164px;
	}
	.safetyform-field-part .safetyform-fields .template-btn {
		width: 100%;
	}
	.safetyform-field-part .show-more-btn {
		max-width: 160px;
		width: 100%;
		height: 38px;
	}
	.safetyform-field-part .bottom-btn button {
		font-size: 12px;
	}
}
@media (max-width: 1024px) {
	.safetyform-card-body {
		height: auto;
		padding: 0px;
	}
	.add-field-area {
		max-width: 290px;
		padding: 24px 0px 25px 16px;
	}
	.safetyform-field-part .safetyform-fields .template-btn {
		width: calc(50% - 4px);
	}
	.field-area {
		padding: 24px 14px 0;
		margin: 0 0 16px 0;
		max-width: calc(100% - 292px);
		max-height: 520px;
	}
	.iphone-view-area {
		max-width: 100%;
		height: auto;
	}
	.iphone-content-part {
		display: table;
		margin: 0 auto 20px;
	}
	.bottom-btn {
		right: 0;
		left: auto;
	}
}

.IoIosAddCircleOutline-custom {
	font-size: 20px;
	font-weight: bolder
}
.signup-form-container {
	display: flex;
	justify-content: space-between;
}

.signup-form-content {
	display: flex
}
.addFieldBtn-span {
	font-weight: 400
}

.bottom-btn button {
	margin: 0 20px;
	border-radius: 40px;
	background-color: transparent;
	border-color: var(--primaryColor);
	color: var(--primaryColor);
}

.bottom-btn button:hover {
	color: var(--whiteColor);
}

.bottom-btn-disabled button {
	margin: 0 20px;
	border-radius: 40px;
	background-color: transparent;
	color: var(--primaryColor);
	border-color: #d0d0d0;
    opacity: 0.5;
	cursor: auto;
}

.object-details {
    position: relative;
	cursor: pointer;
    width: auto;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    max-width: 200px;
    padding: 4px 16px;
    border: 1px solid var(--primaryColor);
    border-radius: 4px;
    transition: 0.4s all;
	margin-bottom: 10px;
	display: flex;
    align-items: center;
}
.object-details .IoIosAddCircleOutline-custom {
	margin: 0 4px 0 0;
	font-size: 18px;
}
.object-details .edit-delete-icon {
	font-size: 12px;
	margin: 0 0 0 6px;
}
.object-details .drag-dots {
	max-width: 8px;
    position: absolute;
    left: 0px;
    opacity: 0;
	transition: 0.3s all;
	cursor: grab;
}
.object-details:hover .drag-dots {
	opacity: 1;
	left: 5px;
	transition: 0.3s all;
}
.object-details span {
	width: calc(100% - 40px);
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Plugin Editor Css */
.editor-content-area {
	border: 1px solid var(--offWhiteColor);
	margin: 0 0 8px;
	min-height: 140px;
    max-height: 360px;
}
.editor-part .rdw-image-modal-btn:disabled {
	color: var(--secondaryColor);
}
.editor-part .rdw-embedded-modal button {
	font-size: 12px;
	font-weight: 600;
	color: var(--tertiaryColor);
}
.editor-part .rdw-embedded-modal-header,
.editor-part .rdw-editor-toolbar {
	font-size: 12px;
}
/* Mobile Preview */
.iphone-content-part {
	position: relative;
}
.iphone-content-part .mobile-preview-content {
    position: absolute;
    z-index: 1;
    background-color: var(--whiteColor);
    background-color: #efeff4;
    width: 244px;
    height: 429px;
    left: 52px;
    top: 67px;
    border-radius: 4px;
    word-break: break-word;
}
.iphone-content-part .mobile-preview-content .mobile-scrolling-area {
    height: calc(100% - 76px);
    overflow: hidden;
    overflow-y: auto;
}

/* Mobile Submit Button */
.mobile-submit-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 16px;
    height: 76px;
	background-color: var(--whiteColor);
}
.mobile-submit-btn .m-btn {
    height: 44px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    background: var(--primaryColorGB);
    color: var(--whiteColor);
    box-shadow: 0px 10px 10px -7px var(--grayColor);
    border: none;
}

/* Mobile Preview ~ Components */
.mobile-field {
	margin: 0 0 10px 0;
	font-size: 12px;
    background-color: var(--whiteColor);
}
.mobile-field p {
	margin: 0;
}
.mobile-field .m-box-header {
    /* padding: 14px 16px; */
    padding: 12px 16px;
	color: var(--tertiaryColor);
	border-bottom: 1px solid var(--primaryColorAlpha);
}
.mobile-field .m-box-header p.title {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	font-weight: 700;
	font-size: 12px;
	margin: 0px;
	letter-spacing: 0.2px;
}
.mobile-field .m-box-header .require-symbol {
    position: absolute;
    left: -8px;
    top: 2px;
	color: var(--errorColor) !important;
}
.mobile-field .m-box-header .subtitle {
	display: block;
	width: 100%;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.5px;
	color: var(--secondaryColor);
}
.mobile-field .m-box-header.checkbox .custom-checkbox {
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.mobile-field .m-box-header.checkbox .custom-checkbox span {
    padding-left: 0;
}
.mobile-field .m-box-content {
    /* padding: 14px 16px; */
    padding: 12px 16px;
}
.mobile-field .m-box-content .m-input {
	border: none;
	border-bottom: 1px solid var(--grayColor);
	font-size: 12px;
    border-radius: 0px;
    padding: 0;
}
.mobile-field .m-box-content .m-input:focus {
	outline: none;
	box-shadow: none;
}
.mobile-field .m-box-content .text-area {
	border: none;
	font-size: 12px;
    border-radius: 0px;
    padding: 0;
}
.mobile-field .m-box-content .text-area:focus {
	outline: none;
	box-shadow: none;
}

/* Custom Checkbox Mobile */
.custom-checkbox.round {
	width: 100%;
    margin: 8px 0 0;
}
.custom-checkbox.round label {
	display: inline-block;
}
.custom-checkbox.round span {
	font-size: 12px;
    border-radius: 50%;
}
.custom-checkbox.round .ant-checkbox-checked .ant-checkbox-inner {
	background-color: var(--primaryColor);
	border-color: var(--primaryColor);
}
.custom-checkbox.round .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-checkbox.round .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-checkbox.round .ant-checkbox:hover .ant-checkbox-inner,
.custom-checkbox.round .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: var(--primaryColor);
}
.custom-checkbox.round .ant-checkbox-checked::after {
    border-radius: 50%;
}
.custom-checkbox.round:first-child {
	margin: 0px;
}

/* Mobile Rating */
.mobile-field .m-star-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
.mobile-field .m-star-group i {
    margin: 0 4px;
    color: var(--primaryColor);
    cursor: pointer;
}
.mobile-field .ant-rate-star-full {
    color: var(--primaryColor);
}
.mobile-field .m-star-rating-text {
    margin: 8px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.mobile-field .m-star-rating-text span {
    width: calc(50% - 10px);
    text-align: center;
    background: var(--primaryColorAlpha);
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    padding: 4px 2px;
    border-radius: 18px;
}

/* Mobile Switch */
.mobile-field .yes-no-switch {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}
.mobile-field .yes-no-switch label {
    width: 50%;
    text-align: center;
    height: 30px;
    line-height: 30px;
}
.mobile-field .yes-no-switch label.ant-radio-button-wrapper-checked {
    background: var(--primaryColor);
    border-color: var(--primaryColor);
}

/* Mobile Image upload */
.mobile-field .add-img-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--grayColor);
    color: var(--secondaryColor);
    font-weight: 500;
    background: transparent;
    height: 40px;
    border-radius: 4px;
}
.mobile-field .add-img-btn i {
    margin: 0 8px 0 0;
    font-size: 16px;
}

/* Mobile Range Slider */
.mobile-field .m-rangslider {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.mobile-field .m-rangslider .custom-range-slider {
    width: calc(100% - 78px);
    margin: 0px;
}
.mobile-field .m-rangslider .custom-range-slider .ant-slider-track {
    background-color: var(--primaryColor);
}
.mobile-field .m-rangslider .custom-range-slider .ant-slider-rail {
    background-color: var(--primaryColorAlpha);
}
.mobile-field .m-rangslider .custom-range-slider .ant-slider-handle {
    border: 2px solid var(--primaryColor);
}
.mobile-field .m-rangslider .slider-count {
    width: 60px;
}

/* Error */
.mobile-field.error .m-box-header {
	color: var(--errorColor);
}

.mobile-scrolling-area .spinner {
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.fixedtable-checkbox-row {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    flex-direction: row;
}
.fixedtable-checkbox-row .colContent {
    padding: 0;
    width: 100%;
    margin: 5px 0 0 0;
}

/* ANT-D CSS - CUSTOMIZE */
.ant-input-search input {
  padding: 6px 14px;
  font-size: 14px;
  height: 44px;
  background: transparent;
  box-shadow: none !important;
  color: var(--tertiaryColor);
  border: 1px solid var(--grayColor);
  border-color: var(--grayColor) !important;
}
.ant-input-search input:hover {
	border: 1px solid;
	border-color: var(--tertiaryColor) !important;
}
.ant-input-search input:focus {
	border: 1px solid;
	border-color: var(--tertiaryColor) !important;
}
.ant-input-search .anticon-search svg {
  width: 18px;
  height: 22px;
  fill: var(--secondaryColor);
}


/* ANT-D CSS- THEME TABLE Design */
.output-table-header {
  margin: 0 0 12px;
}
.output-table-header p {
  margin: 0 0 6px;
  color: var(--secondaryColor);
  font-size: 14px;
}
.output-table-header p span {
  font-weight: 600;
  color: var(--tertiaryColor);
}
.ant-table .ant-table-body {
  overflow: hidden;
  overflow-x: auto;
}
.ant-table table {
  background-color: var(--whiteColor);
}
.ant-table .ant-table-thead > tr > th {
  padding: 16px 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  border-bottom: 1px solid var(--primaryColor) !important;
  /* color: var(--primaryColor) !important; */
  color: var(--tertiaryColor) !important;
  background: var(--primaryColorAlpha) !important;
}
.ant-table .ant-table-tbody > tr > td {
  color: var(--tertiaryColor);
  padding: 14px 10px;
}
.ant-table .ant-table-tbody > tr > td p {
  margin: 0px;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table .ant-table-tbody > tr:hover > td {
  background: var(--primaryColorAlpha) !important;
  background-color: var(--primaryColorAlpha) !important;
}
.ant-table .ant-table-tbody > tr > td svg {}
.ant-table a {
  transition: 0.3s all;
  color: var(--primaryColor);
}
.ant-table a:hover {
  color: var(--darkBlue);
  transition: 0.3s all;
}
.ant-table .table-action-link {
  margin: 0px;
  font-weight: 500;
  cursor: pointer;
  color: var(--primaryColor);
  transition: 0.3s all;
}
.ant-table .table-action-link:hover {
  color: var(--darkBlue);
  transition: 0.3s all;
}
.ant-table .table-action-link.box-link {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  background-color: var(--primaryColorAlpha);
}
.ant-table .table-action-link.box-link:hover {
  transition: 0.3s all;
  background-color: var(--primaryColor);
  color: var(--whiteColor);
}
.ant-table .warning-box-link {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all;
  color: var(--warningColor);
  background-color: var(--warningColorAlpha);
}
.ant-table .warning-box-link:hover {
  transition: 0.3s all;
  background-color: var(--warningColor);
  color: var(--whiteColor);
}
/* Table ~ Pagination */
.ant-table-pagination {}
.ant-table-pagination .ant-pagination-item-active {
  border-color: var(--primaryColor);
}
.ant-table-pagination .ant-pagination-item-active a{
  color: var(--primaryColor);
}
.ant-table-pagination .ant-pagination-item:hover,
.ant-table-pagination .ant-pagination-item:focus,
.ant-table-pagination .ant-pagination-item-active:focus,
.ant-table-pagination .ant-pagination-item-active:hover {
  border-color: var(--darkBlue);
}
.ant-table-pagination .ant-pagination-item:hover a,
.ant-table-pagination .ant-pagination-item:focus a,
.ant-table-pagination .ant-pagination-item-active:focus a,
.ant-table-pagination .ant-pagination-item-active:hover a {
  color: var(--darkBlue);
}
.ant-table-pagination .ant-pagination-prev:hover a,
.ant-table-pagination .ant-pagination-next:hover a,
.ant-table-pagination .ant-pagination-prev:focus a,
.ant-table-pagination .ant-pagination-next:focus a {
  color: var(--darkBlue);
  border-color: var(--darkBlue);
}

.shadow-table .ant-table .ant-table-tbody > tr > td,
.shadow-table .ant-table .ant-table-thead > tr > th {
  padding: 12px 8px;
}
.shadow-table .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: 4px 0 16px -8px rgba(0,0,0,0.15);
}
.shadow-table .ant-table.ant-table-scroll-position-right .ant-table-fixed-left,
.shadow-table .ant-table.ant-table-scroll-position-middle .ant-table-fixed-left {
  box-shadow: 8px 0 20px -6px rgba(0,0,0,0.15);
}


/* ANT-D -CSS Pop Confirm */
.custom-popconfirm .ant-popover-inner-content {
  color: var(--secondaryColor);
  padding: 10px 16px;
}
.custom-popconfirm .ant-popover-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.custom-popconfirm .ant-popover-buttons button {
  margin: 0px;
  display: inline-block;
  border-color: var(--grayColor);
  color: var(--tertiaryColor);
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  margin: 0 0 0 6px;
  max-width: 86px;
  transition: 0.3s all;
}
.custom-popconfirm .ant-popover-buttons button.ant-btn-primary {
  border-color: var(--primaryColor);
  color: var(--whiteColor);
  background-color: var(--primaryColor);
}
.custom-popconfirm .ant-popover-buttons button:hover {
  color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.custom-popconfirm .ant-popover-buttons button.ant-btn-primary:hover {
  transition: 0.3s all;
  color: var(--whiteColor);
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}

/* EDIT RIG ~ Pages  */
.edit-rig-area .rig-photo-form-img-area {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.edit-rig-area .rig-photo-form-img-area .right-area {
  padding: 0 8px;
  width: calc(100% - 320px);
}
.edit-rig-area .rig-photo-form-img-area .left-area {
  padding: 0 8px;
  max-width: 320px;
  width: 100%;
}
.edit-rig-pic-part, .edit-rig-asset-part, .create-new-user-part {
  padding-top: 36px;
}
.edit-rig-area .rig-photos-part {
  position: relative;
  padding: 18px 0;
}
.edit-rig-area .rig-photos-part .user-details {
  text-align: center;
  margin: 15px 0;
}
.edit-rig-area .rig-photos-part .deleteIcon {
  transition: 0.3s all;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  color: var(--errorColor);
  width: 22px;
  height: 22px;
}
.edit-rig-area .rig-photos-part .deleteIcon:hover {
  transition: 0.3s all;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.edit-rig-area .rig-photos-part .sendIcon,
.edit-rig-area .rig-photos-part .archiveIcon {
  transition: 0.3s all;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  color: var(--secondaryColor);
  width: 22px;
  height: 22px;
}
.edit-rig-area .rig-photos-part .sendIcon {
  left: 28px;
}
.edit-rig-area .rig-photos-part .sendIcon:hover,
.edit-rig-area .rig-photos-part .archiveIcon:hover {
  color: var(--tertiaryColor);
}

.edit-rig-area .rig-photo-upload-area {
  display: block;
  margin: 0 auto;
  height: 200px;
  max-width: 200px;
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--whiteColor);
  border: 1px dashed var(--grayColor);
  cursor: pointer;
  transition: 0.4s all;
}
.edit-rig-area .rig-photo-upload-area:hover {
  box-shadow: 2px 2px 32px -16px var(--secondaryColor);
  transition: 0.4s all;
}
.edit-rig-area .rig-photo-upload-area .ant-upload-select {
  height: 200px;
  width: 100%;
  padding: 8px;
}
.edit-rig-area .rig-photo-upload-area img.edit-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.edit-rig-area .rig-photo-upload-area .upload-btn {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  border-radius: 50%;
  background: var(--primaryColorAlpha);
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 600;
}

/* THEME ~> Form > Input & Chec CSS */
.theme-form {}
.theme-form > .ant-row {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
}
.theme-form > .ant-row > .ant-col {
  padding: 0 8px;
}
.theme-form .ant-form-item {
  margin: 0 0 14px;
  padding-bottom: 0 !important;
}
.theme-form .ant-form-item .ant-form-item-label {
  padding: 0px;
}
.theme-form .ant-form-item label {
  color: var(--tertiaryColor);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin: 0 0 6px;
}
.theme-form .ant-form-item input.ant-input {
  padding: 6px 14px;
  font-size: 14px;
  height: 44px;
  background: transparent;
  color: var(--tertiaryColor);
  box-shadow: none;
  border: 1px solid var(--grayColor);
  border-color: var(--grayColor) !important;
}
.theme-form .ant-form-item input.ant-input:hover {
  border-color: var(--tertiaryColor) !important;
}
.theme-form .ant-form-item input.ant-input:focus {
  border-color: var(--tertiaryColor) !important;
}
.theme-form .custom-select-multiple .ant-select-selection {
  box-shadow: none;
  min-height: 44px;
  border-color: var(--grayColor) !important;
}
.theme-form .custom-select-multiple .ant-select-selection:hover {
  border-color: var(--tertiaryColor) !important;
}
.theme-form .custom-select-multiple .ant-select-selection ul .ant-select-selection__choice{
  border: none;
  color: var(--primaryColor);
  background-color: var(--primaryColorAlpha);
}
.theme-form .custom-select-multiple .ant-select-selection ul .ant-select-selection__choice i {
  color: var(--tertiaryColor);
}
.theme-form .button-section {
  justify-content: flex-end;
}
.theme-form .button-section .submitBtn {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.theme-form .button-section .submitBtn:hover {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}
/* Checkbox */
.theme-form .custom-checkbox label {
  display: inline-block;
}
.theme-form .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.theme-form .custom-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.theme-form .custom-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.theme-form .custom-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.theme-form .custom-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primaryColor);
}

/* Dropdown Option Dropdown */
.theme-form .custom-dropdown-part {
  height: 44px;
  color: var(--primaryColor) !important;
  border: 1px solid var(--grayColor);
  box-shadow: none !important;
  font-weight: 500;
  outline: none;
}
.theme-form .custom-dropdown-part:focus,
.theme-form .custom-dropdown-part:hover {
  border: 1px solid var(--tertiaryColor);
}
.custom-dropdown-option {}
.custom-dropdown-option ul {
  padding: 8px 0;
}
.custom-dropdown-option ul li {
  font-size: 14px;
  color: var(--tertiaryColor);
}
.custom-dropdown-option ul li:hover {
  background-color: var(--primaryColorAlpha);
}
/* Select Option Dropdown */
.custom-select-option {}
.custom-select-option ul {
  padding: 8px 0;
}
.custom-select-option ul li {
  font-size: 14px;
  color: var(--tertiaryColor);
}
.custom-select-option ul li.ant-select-dropdown-menu-item-active {
  background-color: var(--primaryColorAlpha) !important;
}
.custom-select-option ul li:hover {
  background-color: var(--primaryColorAlpha) !important;
}
/* EDIT ~ Modal */
.send-mail-modal {
  padding: 0px !important;
}
.send-mail-modal .ant-modal-close {
  max-width: 50px;
  margin: 0px 6px;
  cursor: pointer;
}
.send-mail-modal .ant-modal-header .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--primaryColor);
  text-transform: capitalize;
}
.send-mail-modal .ant-modal-body .ant-form-item-label {
  width: 100%;
}
.send-mail-modal .ant-modal-body .ant-col {
  width: 100%;
  padding: 0px;
}
.send-mail-modal .ant-modal-footer {
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.send-mail-modal .ant-modal-footer > div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
}
.send-mail-modal .ant-modal-footer .ant-btn {
  border-color: var(--grayColor);
  color: var(--tertiaryColor);
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  max-width: 140px;
}
.send-mail-modal .ant-modal-footer .ant-btn:hover {
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}
.send-mail-modal .ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.send-mail-modal .ant-modal-footer .ant-btn.ant-btn-primary:hover {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}

/* Custom ~ TABS */
.custom-tabs {
  margin: 24px 0 0;
}
.custom-tabs .ant-tabs-bar {
  border-bottom: 1px solid var(--grayColor);
  margin: 0 0 8px;
}
.custom-tabs .ant-tabs-tab {
  color: var(--secondaryColor);
  margin: 0 12px 0 0;
  padding: 12px 14px;
}
.custom-tabs .ant-tabs-tab:hover {
  color: var(--tertiaryColor) !important;
}
.custom-tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: var(--primaryColor);
}
.custom-tabs .ant-tabs-ink-bar {
  background-color: var(--primaryColor);
}
.custom-tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0 12px 0 0;
}

/* Button */
button {
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  border-radius: 8px;
  border: 1px solid transparent;
  color: var(--whiteColor);
  background-color: var(--primaryColor);
}

.main-content {
  background-color: '#fff'
}
