/* Filter Table CSS */
.filter-table-area {
    position: absolute;
    left: 0;
    z-index: 2;
    width: 100%;
    max-width: 240px;
}
.filter-table-area .filter-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 48px;
    height: 48px;
    background-color: var(--whiteColor);
    box-shadow: 1px 1px 14px 2px var(--offWhiteColor);
    margin: 0 0 8px;
    border-radius: 6px;
}
.filter-table-area .filter-btn svg {
    font-size: 26px;
}
.filter-table-area .filter-btn.active {
    background-color: var(--primaryColor);
}
.filter-table-area .filter-btn.active svg {
    filter: brightness(0) invert(1);
}
.filter-column-box {
    position: relative;
    top: -56px;
    left: 56px;
    user-select: none;
}
.filter-column-box ul {
    border-radius: 4px;
    border: none;
    height: 300px;
    overflow: auto;
    box-shadow: 1px 2px 20px 0px var(--offWhiteColor) !important;
    scrollbar-color: var(--primaryColor) var(--whiteColor);
    scrollbar-width: thin;
}
.filter-column-box ul li {
    height: auto !important;
    padding: 0 12px !important;
    margin: 0px !important;
    text-transform: capitalize;
    color: var(--secondaryColor);
    border-bottom: 1px solid var(--offWhiteColor);
}
.filter-column-box ul li:hover {
    color: var(--primaryColor);
}
.filter-column-box ul li.ant-menu-item-selected {
    color: var(--tertiaryColor);
    background-color: var(--primaryColorAlpha) !important;
    border-bottom: 1px solid var(--primaryColorAlpha);
}
.filter-column-box ul li:last-child {
    border: none;
}
/* Scrollbar css */
.filter-column-box ul::-webkit-scrollbar {
    width: 5px;
}
.filter-column-box ul::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}
.filter-column-box ul::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}