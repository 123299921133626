.groups-icon-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 0 0;
}
.groups-icon-title i {
    font-size: 26px;
    margin: 0 8px 0 0;
    color: var(--primaryColor);
}

/* Sticky Groups body */
.sticky-accordion-part {
    position: sticky;
    top: 0;
    z-index: 11;
    padding: 16px 0 20px;
    margin: 0 0 16px 0;
    border-bottom: 1px solid var(--primaryColorAlpha);
    background: var(--whiteColor);
}
.sticky-accordion-part .site-btn.transparent {
    margin: 12px 0 0;
    padding: 0;
    height: auto;
    background: transparent;
    color: var(--primaryColor);
    border: transparent;
    font-weight: 600;
    font-size: 16px;
}
.sticky-accordion-part .site-btn.transparent:hover {
    color: var(--darkBlue);
}

/* Accordion */
.accordion-custom {
    border: 1px solid var(--primaryColorAlpha);
    padding: 0;
    margin: 0 0 32px;
}
.accordion-custom .accordion-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 !important;
    padding: 16px;
    border-left: 6px solid var(--primaryColor) !important;
    color: var(--tertiaryColor);
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.accordion-custom .accordion-head .group-right-chips {
    background-color: var(--primaryColorAlpha);
    color: var(--secondaryColor);
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 12px;
    line-height: 1;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}
.accordion-custom .accordion-head .group-right-chips i {
    margin: 0 4px 0 0;
    font-size: 14px;
}
.accordion-custom .accordion-head .group-right-chips span {
    font-weight: 700;
    margin: 0 4px 0 0;
}
.accordion-custom .accordion-head .dragIcon {
    cursor: grabbing;
    width: 12px;
    margin: 0 6px 2px 0;
    user-select: none;
}
.accordion-custom .accordion-head .deletIcon {
    margin: 0 0 0 6px;
    display: inline-block;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.3s all;
}
.accordion-custom .accordion-head:hover .deletIcon {
    opacity: 1;
    transition: 0.3s all;
    transform: translateX(6px);
}
.accordion-custom .accordion-head .deletIcon:hover {
    opacity: 0.7;
    transition: 0.3s all;
    transform: translateX(6px) scale(0.9);
}
.accordion-custom .accordion-head .left-part {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.accordion-custom .accordion-head .left-part .drag-input{
    display: flex;
    align-items: center;
}

/* Active Head */
.accordion-custom.active .accordion-head {
    background-color: var(--dashBordBG);
}
/* Accordion content */
.accordion-custom .accordion-content {
    margin: 0 0 0;
    padding: 16px 16px 8px;
    border-top: 1px solid var(--primaryColorAlpha);
}
.accordion-custom .accordion-content .add-group-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 6px 0 4px;
}
.accordion-custom .accordion-content .add-group-form input.group-input{
    border: 1px solid var(--grayColor);
    color: var(--tertiaryColor);
    box-shadow: none;
    border-radius: 4px;
    height: 44px;
    font-weight: 400;
    margin: 0 0 6px 0;
    width: 50%;
}
.accordion-custom .accordion-content .add-group-form input.group-input:focus {
    border-color: var(--primaryColor);
}
.accordion-custom .accordion-content .add-group-form .select-option-part {
    width: calc(50% - 136px);
}
.accordion-custom .accordion-content .add-group-form .select-option-part p.error {
    color: var(--errorColor);
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple {
    width: 100%;
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple .ant-select-selection {
    box-shadow: none;
    padding: 2px 0 0 0;
    font-size: 12px;
    min-height: 44px;
    height: 44px;
    overflow: auto;
    border-color: var(--grayColor) !important;
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple .ant-select-selection:hover {
    border-color: var(--primaryColor) !important;
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple .ant-select-selection ul .ant-select-selection__choice{
    border: none;
    font-size: 12px;
    font-weight: 500;
    color: var(--primaryColor);
    background-color: var(--primaryColorAlpha);
}
.accordion-custom .accordion-content .add-group-form .custom-select-multiple .ant-select-selection ul .ant-select-selection__choice i {
    color: var(--tertiaryColor);
}
.accordion-custom .accordion-content .add-group-form .submitBtn {
    height: 44px;
    font-size: 14px;
    font-weight: 500;
}


.addSegment-accordion .accordion-head .left-part {
    display: flex;
    align-items: center;
}
.addSegment-accordion .accordion-head input.add-rename-input {
    border: 1px dashed var(--primaryColor);
    margin: 0 0 0 6px;
    box-shadow: none;
    color: var(--tertiaryColor);
    font-weight: 600;
}


.accordion-custom .avatar-table-part {
    display: flex;
}
.accordion-custom .avatar-table {
    border: 2px solid var(--whiteColor);
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36px;
    background: var(--primaryColor);
    color: var(--whiteColor);
}
.avatar-table-part .avatar-table:nth-child(3), .avatar-table-part .avatar-table:nth-child(2) {
    margin: 0 0px 0 -14px;
}
.accordion-custom .avatar-table.number {
    background: var(--tertiaryColor);
    margin: 0 0 0 -14px;
}

.group-name-txt {
    display: flex;
}

.group-name-txt span {
    opacity: 0.0;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    justify-content: center;
    margin-left: 5px;
}
.group-name-txt:hover span {
    opacity: 0.3;
    display: inline-block;
}