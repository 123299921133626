.row-details {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.row-details > .ant-col {
    padding: 0 8px;
}

/* Report Details ~ Header */
.report-header {
    padding: 16px 8px;
    background-color: var(--primaryColorAlpha);
    color: var(--primaryColor);
    margin: 0 0 32px;
}
.report-header h3 {
    color: var(--primaryColor);
    font-weight: 700;
}
.report-content .rig-logo {
    margin: 0 0 16px;
    text-align: center;
}
.report-content .rig-logo img {
    max-width: 300px;
    width: 100%;
}
.report-content .report-title-name {
    position: relative;
    font-weight: 600;
    padding: 0 0 16px;
    margin-bottom: 40px !important;
}
.report-content .report-title-name:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background: var(--primaryColor);
    margin: 0 auto;
}

/* Report Details ~ CopyRight */
.report-content .copy-right-content {
    margin: 32px 0 0;
    padding: 16px;
    font-size: 14px;
    background-color: var(--primaryColorAlpha);
    color: var(--tertiaryColor);
}
.report-content .copy-right-content p {
    margin: 0px;
    text-align: center;
}


.report-content .report-details {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.report-content .report-details .data-texts{
    word-break: break-all;
    width: 25%;
    padding: 0 10px;
    margin: 0 0 16px;
}
.report-content .report-details .data-texts b {
    display: block;
}
.report-content .report-details .data-texts .chips {
    padding: 4px 8px;
    background-color: var(--primaryColorAlpha);
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    margin: 6px 6px 0px 0;
    display: inline-block;
}


/* Report Details ~ Rig Pics */
.rig-pic-report .date-rig-pics {
    font-size: 16px;
    line-height: 1.4;
    color: var(--tertiaryColor);
    font-weight: 700;
    padding: 6px 12px;
    display: inline-block;
    margin-bottom: 8px !important;
    background: var(--primaryColorAlpha);
    border-radius: 6px;
}
.rig-pic-report .rig-pics-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.rig-pic-report .rig-box {
    width: 25%;
    padding: 0 10px;
    margin: 16px 0 0;
}
.rig-pic-report .rig-box .rig-box-inner .image-part {
    margin: 0 0 16px 0;
    box-shadow: 0px 0px 2px 1px rgba(145,158,171,0.16);
    border-radius: 6px;
}
.rig-pic-report .rig-box .rig-box-inner .image-part img {
    border-radius: 6px;
    width: 100%;
    height: 15.625VW; /* 300px */
    object-fit: cover;
}

.signature-blocks {
    margin: 0 0 16px 0;
}
.signature-blocks .rig-pics-row .rig-box {
    width: 100%;
}
.signature-blocks .rig-pics-row .rig-box .image-part-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
}
.signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part {
    width: calc(25% - 16px);
    margin: 0 8px 16px;
    overflow: hidden;
    position: relative;
}
.signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part span {
    position: absolute;
    top: 0;
    width: 100%;
    display: block;
    background: var(--darkBlue);
    color: var(--whiteColor);
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 5px;
}


/* Report Details ~ LINE HR */
hr.report-line {
    width: 100%;
    height: 0px;
    border: none;
    border-bottom: 1px solid var(--primaryColorAlpha);
    margin: 16px 0 32px;
}

/* Report Details ~ Rig Pics */
.details-part {
    display: flex;
    flex-wrap: wrap;
}
.small-details-box-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.small-details-box {
    width: calc(33.33% - 8px);
    background: var(--whiteColor);
    box-shadow: 0px 0px 6px -1px var(--grayColor);
    padding: 12px;
    font-size: 14px;
    border-radius: 4px;
    margin: 0 8px 16px;
}
.small-details-box p {
    margin: 0 0 6px;
    font-size: 14px;
}
.small-details-box p.date {
    font-weight: 700;
}
.small-details-box .chips-blog {
    display: inline-block;
    background-color: var(--primaryColorAlpha);
    color: var(--tertiaryColor);
    padding: 4px 6px;
    margin: 6px 6px 0 0;
    font-size: 12px;
    font-weight: 600;
}

/* Company Report */
.company-reports-box {
    width: 100%;
    background: var(--dashBordBG);
    box-shadow: 0px 0px 6px -1px var(--grayColor);
    padding: 12px;
    font-size: 14px;
    border-radius: 0px;
    margin: 0 0 16px 0;
}
.company-reports-box .table-company {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--whiteColor);
    width: 100%;
}
.company-reports-box .table-company .ant-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.company-reports-box .table-company .ant-row:nth-child(even) {
    background-color: var(--offWhiteColor);
}
.company-reports-box .table-company .ant-row .ant-col {
    width: 33.33%;
    padding: 8px;
}
.company-reports-box .table-company .ant-row.table-head {
    background: var(--primaryColorGB);
    color: var(--whiteColor);
}
.company-reports-box .table-company .ant-row .ant-col p {
    margin: 0px;
}
.company-reports-box .table-company .table-body {
    width: 100%;
}
.spacing-top {
    margin-top: 20px;
}
.spacing-bottom {
    margin-bottom: 20px;
}
.rig-down {
    color: red;
}
.rig-up {
    color: black;
}


/* Report Details ~ Responsive */
@media (max-width: 1440px) {
    .small-details-box {
        width: calc(50% - 8px);
    }
    .signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part,
    .rig-pic-report .rig-box {
        width: calc(33.33% - 16px);
    }
    .rig-pic-report .rig-box .rig-box-inner .image-part img {
        height: 19.4446VW; /* 280px */
    }
}
@media (max-width: 1280px) {
    .report-content .report-details .data-texts{
        width: 33.33%;
    }
}
@media (max-width: 1024px) {
    .report-content .report-details .data-texts{
        width: 50%;
    }
    .signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part,
    .rig-pic-report .rig-box {
        width: calc(50% - 16px);
    }
    .rig-pic-report .rig-box .rig-box-inner .image-part img {
        height: 24.4140625VW; /* 250px */
    }
    .small-details-box {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .signature-blocks .rig-pics-row .rig-box .image-part-row  .image-part,
    .rig-pic-report .rig-box {
        width: 100%;
    }
    .rig-pic-report .rig-box .rig-box-inner .image-part img {
        height: 52.17391304347826VW; /* 300px */
    }
    .report-content .report-details .data-texts{
        width: 100%;
    }
}