.mainClass {
    text-align: inherit !important;
    position: relative;
    clear: both;
    width: 100%;
}
.container{
	position: relative;
	width: 100%;
	max-width: 1170px;
	margin: 0 auto;
}
.rigInfo{
    background: gainsboro;
    padding: 10px 0;
    position: relative;
    color: #ea6863;
    margin: 1vw 0;
    border-radius: 8px;
}
/*rigHeader css*/
.site_header {
    background-color: #343f4b;
    color: #e87532;
    text-align: center;
    margin: 0 0 2vw 0;
    padding: 1vw 0;
    position: relative;
}
.rig_header h1{
	margin: 0;
}
/*rig Footer css*/
.site_footer{
	position: relative;
	bottom: 0;
	background-color: #343f4b;
    color: #e87532;
    text-align: center;
    width: 100%;
    padding: 1vw 0;
}
.p-last-child{
	margin-bottom: 0;
}
/*rigInfoSection-three css*/
.rigInfoSection-three{
	text-align: center;
	background-color: white;
}
.rigInfoSection-three .head-value{
	padding-bottom: 5px;
	font-size: 2.5rem;
	font-weight: bold;
	color: white;
}
.rigInfoSection-three .body-value{
	color: #FFF;
	font-size: 1.25rem;
}
.rigInfoSection-three .col-md-4{
	padding: 15px 0;
}
.rigInfoSection-three-inner{
	background-color: #343f4b;
    margin: 0 15px;
    padding: 15px;
    color: white
}
/*rigInfoSection-one css*/
.rigInfoSection-one{
	text-align: center;
}
.rigInfoSection-one p{
	font-weight: bold;
}
/*rigInfoSection-two css*/
.rigInfoSection-two{
	text-align: center;
}
.rigInfoSection-two .sky-blue{
	font-size: 20px;
	font-weight: bold;
}
.rigInfoSection-two .date{
	color: gray;
}
.relase-on{
	color: black;
}
/*rigInfoSection-open css*/
.rigInfoSection-open{
	padding: 10px 0;
	font-size: 18px;
}
.loader {
    margin: 17% 40%;
}
.create-report {
	margin-top: 7vh;
}
.rig-info-section {
	margin-bottom: 20px;
 }
.create-report-section {
	display: flex;
    justify-content: flex-end;
}
.create-report-tabs .ant-row .left-col {
	padding-right: 8px;
}
.create-report-tabs .ant-row .right-col {
	padding-left: 8px;
}
.theme-form .button-section-generate {
	justify-content: center;
	margin-top: 26px !important;
}