/* Quick Action - Section */
.user-action-part {
    display: flex;
    flex-wrap: wrap;
    margin: 16px -8px 0;
}
.user-action-part .user-boxes {
    width: 33.33%;
    padding: 0 8px;
    margin: 0 0 16px;
}
.user-action-part .user-boxes a {
    display: inline-flex;
    border: 1px solid var(--grayColor);
    background-color: var(--whiteColor);
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 22px;
    width: 100%;
    transition: 0.5s all;
}
.user-action-part .user-boxes a img {
    max-width: 60px;
    margin: 0 0 8px;
}
.user-action-part .user-boxes a p {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: var(--tertiaryColor);
}
.user-action-part .user-boxes a:hover {
    transition: 0.5s all;
    background-color: var(--primaryColorAlpha);
    border-color: var(--darkBlue);
}

/* Rigs info - Section */
.rigs-info-block {
    margin: 24px 0 0 0;
}
.rigs-card-part .small-title {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 6px;
    color: var(--tertiaryColor);
    width: 100%;
    display: block;
}
.rigs-card-part .riginfo-boxes {
    padding: 14px;
}
.rigs-card-part .riginfo-boxes.border-r-dash {
    border-right: 1px dashed var(--grayColor);
}
.rigs-card-part .riginfo-boxes .riginfo-details {
    margin: 0 0 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 8px;
    border-bottom: 1px solid var(--offWhiteColor);
}
.rigs-card-part .riginfo-boxes .riginfo-details:last-child {
    border: none;
}
.rigs-card-part .riginfo-boxes .riginfo-details span {
    position: relative;
    display: block;
    padding-left: 16px;
    margin-left: 10px;
}
.rigs-card-part .riginfo-boxes .riginfo-details span:before {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 8px;
    background-color: var(--grayColor);
}
.rigs-card-part .riginfo-boxes .riginfo-details .name {
    font-weight: 600;
    font-size: 14px;
    color: var(--primaryColor);
}
.rigs-card-part .riginfo-boxes .riginfo-details .number {
    font-weight: 600;
    font-size: 14px;
    color: var(--tertiaryColor);
}

/* Date Picker */
.custom-date-picker {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 8px;
}
.custom-date-picker .date_picker {
    margin: 0px;
    width: 100%;
}
.custom-date-picker .date_picker input {
    border-radius: 0px;
    display: flex;
    color: var(--tertiaryColor);
    border: none;
    padding: 22px 10px;
    box-shadow: 0 0px 8px -2px var(--grayColor);
}
.custom-date-picker .date_picker .ant-calendar-picker-icon {
    color: var(--tertiaryColor);
}
#DropdownFixPostion .ant-calendar-input {
    border: none !important;
    padding-left: 0;
    font-size: 14px;
}


/* Report Details Card */
.report-details-card .small-title {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 6px;
    color: var(--tertiaryColor);
    width: 100%;
    display: block;
}
.most-recent-rig-cards {
    margin: 24px 0 0 0;
}
.most-recent-rig-cards .ant-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.most-recent-rig-cards .boxes {
    width: calc(25% - 16px);
    padding: 16px;
    border-radius: 8px;
    margin: 16px 8px 0;
    background: var(--primaryColorAlpha);
    /* box-shadow: 0px 0px 6px -2px var(--grayColor); */
}
.most-recent-rig-cards .boxes h5 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
}
.most-recent-rig-cards .boxes p {
    margin: 6px 0 0;
}
.most-recent-rig-cards .boxes p span {
    font-weight: 500;
    color: var(--tertiaryColor);
}
.custom-search {
    width: '100%'
}


@media (max-width: 1440px) {
    .most-recent-rig-cards .boxes {
        width: calc(33.33% - 16px);
    }
}
@media (max-width: 991px) {
    .most-recent-rig-cards .boxes {
        width: calc(50% - 16px);
    }
    .rigs-card-part .riginfo-boxes {
        width: 100%;
        border-bottom: 1px dashed var(--grayColor);
        border-right: none;
        margin: 0 0 8px;
    }
    .rigs-card-part .riginfo-boxes.border-r-dash {
        border-right: none;
    }
}