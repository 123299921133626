/* ~~!!!~~~ FONT & COLOR VARIABLE ~~!!!~~~ */
/* GOOGLE Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');

:root {
  /* FONTS */
  --fontStyle1: 'Montserrat', sans-serif;
  
  /* THEME COLORS */
  /* --primaryColor: #D61A0C;
  --primaryColorAlpha: rgba(214, 26, 12, 0.05); */
  --primaryColor: #2998ff;
  --primaryColorAlpha: #f0f6fb;
  --primaryColorGB: linear-gradient(135deg,#2998ff,#7329ff);
  --secondaryColor: #858383;
  --tertiaryColor: #1b1b1b;

  /* DEFAULT COLORS */
  --whiteColor: #ffffff;
  --offWhiteColor: #f2f2f2;
  --dashBordBG: #fbfbfb;
  --blackColor: #000000;
  --grayColor: #cccccc;
  /* --darkBlue: #8B0000; */
  --darkBlue: #007ef3;
  --errorColor: #D2042D;
  --warningColor: #b78103;
  --warningColorAlpha: rgba(255, 193, 7, 0.16);
}

/* GLOBAL CSS */
body {
  margin: 0;
	font-weight: 400;
  font-family: var(--fontStyle1) !important;
}

form input {
	background: transparent;
  color: var(--tertiaryColor);
	border: 1px solid var(--grayColor);
	outline: none;
	border-radius: 6px;
	font-size: 16px;
  margin: 0 0 6px;
  width: 100%;
  display: inline-block;
  outline: none;
  box-shadow: none;
  padding: 12px 18px;
}
form input:hover {
	border: 1px solid var(--tertiaryColor);
}
form input:focus {
	border: 1px solid var(--tertiaryColor);
}

/* Align CSS */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

/* Heading CSS ~ Global */
h1,h2,h3,h4,h5,h6 {
  margin-bottom: 15px !important;
  letter-spacing: 0.35px;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
a {
  color: var(--primaryColor);
  font-weight: 500;
	text-decoration: none;
}
.primaryColor {
  color: var(--primaryColor) !important;
}
.error-text {
  font-weight: 500;
  color: var(--errorColor) !important;
}

/* Margin CSS ~ Class */
.no-ml {
  margin-left: 0px !important;
}
.no-mr {
  margin-right: 0px !important;
}
.no-mb {
  margin-bottom: 0px !important;
}
.no-mt {
  margin-top: 0px !important;
}


/*  DASHBOARD CSS */
.main-dashboard-layout {
  padding: 0 20px 32px;
  width: 100%;
  height: calc(100vh - 66px);
  background: var(--dashBordBG) !important;
}
.card-body {
  margin: 30px 0 0;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--whiteColor);
  box-shadow: 2px 2px 10px -6px var(--grayColor);
}
.card-title {
  font-weight: 700;
  color: var(--primaryColor) !important;
}
.card-title.shade {
  /* background-color: var(--primaryColorAlpha); */
  background: var(--primaryColorGB);
  color: var(--whiteColor) !important;
  padding: 8px 16px;
  border-radius: 8px;
}
.card-title.icon {
  display: flex;
  align-items: center;
}
.card-title.icon svg {
  color: var(--primaryColor);
  margin: 0 8px 0 0;
  cursor: pointer;
  width: 28px;
}
.card-subtitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--tertiaryColor);
  margin-bottom: 16px !important;
}

/* Site Button */
button.site-btn {
  cursor: pointer;
  display: inline-block;
  width: auto;
  height: 48px;
  max-width: 180px;
  padding: 4px 18px;
  border: 1px solid var(--primaryColor);
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  border-radius: 6px;
  transition: 0.4s all;
}
button.site-btn:hover {
  transition: 0.4s all;
  background-color: var(--darkBlue);
}
button.site-btn.rounded {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  max-width: 48px;
  width: 100%;
  padding: 6px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  line-height: 48px;
}
button.site-btn.rounded svg {
  width: 30px;
}
button.site-btn.icon {
  display: flex;
  align-items: center;
}
button.site-btn.icon svg {
  margin: 0 4px 0 0;
}


/* TAB's Card Body */
.card-tab-body {
  padding: 0;
  margin: 30px 0 0;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: none;
}
.card-tab-body .custom-tabs .ant-tabs-bar {
  margin: 0px;
  border: none;
}
.card-tab-body .custom-tabs .ant-tabs-content {
  background-color: var(--whiteColor);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 2px 2px 10px -6px var(--grayColor);
}
.card-tab-body .custom-tabs .ant-tabs-card .ant-tabs-tab {
  background: transparent !important;
  border: none !important;
  margin: 0 16px 0 0 !important;
  font-weight: 500;
}
.card-tab-body .custom-tabs .ant-tabs-card .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--whiteColor) !important;
  box-shadow: inset -1px 2px 5px -3px rgba(0,0,0,0.2);
}

/* CUSTOM SCROLL */
.custom-scroll {
	overflow-y: auto;
  scrollbar-color: var(--primaryColor) var(--whiteColor);
  scrollbar-width: thin;
}
.custom-scroll::-webkit-scrollbar {
  width: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 10px;
}