.fixedtable-checkbox-row {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    flex-direction: row;
}
.fixedtable-checkbox-row .colContent {
    padding: 0;
    width: 100%;
    margin: 5px 0 0 0;
}
