.warning-headline {
    display: flex;
    align-items: center;
    background-color: var(--warningColorAlpha);
    color: var(--warningColor);
    border-radius: 6px;
    margin: 24px 0;
    padding: 16px;
}
.warning-headline h4{
    color: var(--warningColor);
    font-weight: 600;
    padding-left: 8px;
}

/* NOT USED FULL CSS */
.jsa_table{
    margin: 3% 30%;
    width: 45%;
}
.jsa_tr {
    margin-bottom: 3%;
    font-size: 16px;
}
.jsa_details {
    border-bottom: 2px solid #000;
    font-size: 15px;
    font-weight: 700;
    display: block;
    margin-bottom: 5%;
    margin-right: -20%;
}
.jsa_image {
    padding-right: 30px;
    width: 250px;
    height: 300px;    
}
.jsa_image_tr{
    font-weight: 700;
    display: block;
    margin-right: -40%;
}
.declaration {
    font-size: 18px;
    font-weight: 500;
    background-color: bisque;
    border-radius: 5px;
    padding: 2% 2% 2% 5%;
    margin-right: -3%;
    width: 85%;
}

.customed-avatar {
    display: flex;
    border: 0px;
}
.customed-avatar .avatar-table-part {
    display: flex;
}
.customed-avatar .avatar-table {
    border: 2px solid var(--whiteColor);
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36px;
    background: var(--primaryColor);
    color: var(--whiteColor);
}
.avatar-table-part .avatar-table:nth-child(3), .avatar-table-part .avatar-table:nth-child(2) {
    margin: 0 0px 0 -14px;
}
.customed-avatar .avatar-table.number {
    background: var(--tertiaryColor);
    margin: 0 0 0 -14px;
}