/* ANT-D CSS - CUSTOMIZE */
.ant-input-search input {
  padding: 6px 14px;
  font-size: 14px;
  height: 44px;
  background: transparent;
  box-shadow: none !important;
  color: var(--tertiaryColor);
  border: 1px solid var(--grayColor);
  border-color: var(--grayColor) !important;
}
.ant-input-search input:hover {
	border: 1px solid;
	border-color: var(--tertiaryColor) !important;
}
.ant-input-search input:focus {
	border: 1px solid;
	border-color: var(--tertiaryColor) !important;
}
.ant-input-search .anticon-search svg {
  width: 18px;
  height: 22px;
  fill: var(--secondaryColor);
}


/* ANT-D CSS- THEME TABLE Design */
.output-table-header {
  margin: 0 0 12px;
}
.output-table-header p {
  margin: 0 0 6px;
  color: var(--secondaryColor);
  font-size: 14px;
}
.output-table-header p span {
  font-weight: 600;
  color: var(--tertiaryColor);
}
.ant-table .ant-table-body {
  overflow: hidden;
  overflow-x: auto;
}
.ant-table table {
  background-color: var(--whiteColor);
}
.ant-table .ant-table-thead > tr > th {
  padding: 16px 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  border-bottom: 1px solid var(--primaryColor) !important;
  /* color: var(--primaryColor) !important; */
  color: var(--tertiaryColor) !important;
  background: var(--primaryColorAlpha) !important;
}
.ant-table .ant-table-tbody > tr > td {
  color: var(--tertiaryColor);
  padding: 14px 10px;
}
.ant-table .ant-table-tbody > tr > td p {
  margin: 0px;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table .ant-table-tbody > tr:hover > td {
  background: var(--primaryColorAlpha) !important;
  background-color: var(--primaryColorAlpha) !important;
}
.ant-table .ant-table-tbody > tr > td svg {}
.ant-table a {
  transition: 0.3s all;
  color: var(--primaryColor);
}
.ant-table a:hover {
  color: var(--darkBlue);
  transition: 0.3s all;
}
.ant-table .table-action-link {
  margin: 0px;
  font-weight: 500;
  cursor: pointer;
  color: var(--primaryColor);
  transition: 0.3s all;
}
.ant-table .table-action-link:hover {
  color: var(--darkBlue);
  transition: 0.3s all;
}
.ant-table .table-action-link.box-link {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  background-color: var(--primaryColorAlpha);
}
.ant-table .table-action-link.box-link:hover {
  transition: 0.3s all;
  background-color: var(--primaryColor);
  color: var(--whiteColor);
}
.ant-table .warning-box-link {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all;
  color: var(--warningColor);
  background-color: var(--warningColorAlpha);
}
.ant-table .warning-box-link:hover {
  transition: 0.3s all;
  background-color: var(--warningColor);
  color: var(--whiteColor);
}
/* Table ~ Pagination */
.ant-table-pagination {}
.ant-table-pagination .ant-pagination-item-active {
  border-color: var(--primaryColor);
}
.ant-table-pagination .ant-pagination-item-active a{
  color: var(--primaryColor);
}
.ant-table-pagination .ant-pagination-item:hover,
.ant-table-pagination .ant-pagination-item:focus,
.ant-table-pagination .ant-pagination-item-active:focus,
.ant-table-pagination .ant-pagination-item-active:hover {
  border-color: var(--darkBlue);
}
.ant-table-pagination .ant-pagination-item:hover a,
.ant-table-pagination .ant-pagination-item:focus a,
.ant-table-pagination .ant-pagination-item-active:focus a,
.ant-table-pagination .ant-pagination-item-active:hover a {
  color: var(--darkBlue);
}
.ant-table-pagination .ant-pagination-prev:hover a,
.ant-table-pagination .ant-pagination-next:hover a,
.ant-table-pagination .ant-pagination-prev:focus a,
.ant-table-pagination .ant-pagination-next:focus a {
  color: var(--darkBlue);
  border-color: var(--darkBlue);
}

.shadow-table .ant-table .ant-table-tbody > tr > td,
.shadow-table .ant-table .ant-table-thead > tr > th {
  padding: 12px 8px;
}
.shadow-table .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: 4px 0 16px -8px rgba(0,0,0,0.15);
}
.shadow-table .ant-table.ant-table-scroll-position-right .ant-table-fixed-left,
.shadow-table .ant-table.ant-table-scroll-position-middle .ant-table-fixed-left {
  box-shadow: 8px 0 20px -6px rgba(0,0,0,0.15);
}


/* ANT-D -CSS Pop Confirm */
.custom-popconfirm .ant-popover-inner-content {
  color: var(--secondaryColor);
  padding: 10px 16px;
}
.custom-popconfirm .ant-popover-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.custom-popconfirm .ant-popover-buttons button {
  margin: 0px;
  display: inline-block;
  border-color: var(--grayColor);
  color: var(--tertiaryColor);
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  margin: 0 0 0 6px;
  max-width: 86px;
  transition: 0.3s all;
}
.custom-popconfirm .ant-popover-buttons button.ant-btn-primary {
  border-color: var(--primaryColor);
  color: var(--whiteColor);
  background-color: var(--primaryColor);
}
.custom-popconfirm .ant-popover-buttons button:hover {
  color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.custom-popconfirm .ant-popover-buttons button.ant-btn-primary:hover {
  transition: 0.3s all;
  color: var(--whiteColor);
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}

/* EDIT RIG ~ Pages  */
.edit-rig-area .rig-photo-form-img-area {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.edit-rig-area .rig-photo-form-img-area .right-area {
  padding: 0 8px;
  width: calc(100% - 320px);
}
.edit-rig-area .rig-photo-form-img-area .left-area {
  padding: 0 8px;
  max-width: 320px;
  width: 100%;
}
.edit-rig-pic-part, .edit-rig-asset-part, .create-new-user-part {
  padding-top: 36px;
}
.edit-rig-area .rig-photos-part {
  position: relative;
  padding: 18px 0;
}
.edit-rig-area .rig-photos-part .user-details {
  text-align: center;
  margin: 15px 0;
}
.edit-rig-area .rig-photos-part .deleteIcon {
  transition: 0.3s all;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  color: var(--errorColor);
  width: 22px;
  height: 22px;
}
.edit-rig-area .rig-photos-part .deleteIcon:hover {
  transition: 0.3s all;
  transform: scale(1.1);
}
.edit-rig-area .rig-photos-part .sendIcon,
.edit-rig-area .rig-photos-part .archiveIcon {
  transition: 0.3s all;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  color: var(--secondaryColor);
  width: 22px;
  height: 22px;
}
.edit-rig-area .rig-photos-part .sendIcon {
  left: 28px;
}
.edit-rig-area .rig-photos-part .sendIcon:hover,
.edit-rig-area .rig-photos-part .archiveIcon:hover {
  color: var(--tertiaryColor);
}

.edit-rig-area .rig-photo-upload-area {
  display: block;
  margin: 0 auto;
  height: 200px;
  max-width: 200px;
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--whiteColor);
  border: 1px dashed var(--grayColor);
  cursor: pointer;
  transition: 0.4s all;
}
.edit-rig-area .rig-photo-upload-area:hover {
  box-shadow: 2px 2px 32px -16px var(--secondaryColor);
  transition: 0.4s all;
}
.edit-rig-area .rig-photo-upload-area .ant-upload-select {
  height: 200px;
  width: 100%;
  padding: 8px;
}
.edit-rig-area .rig-photo-upload-area img.edit-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.edit-rig-area .rig-photo-upload-area .upload-btn {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  border-radius: 50%;
  background: var(--primaryColorAlpha);
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 600;
}

/* THEME ~> Form > Input & Chec CSS */
.theme-form {}
.theme-form > .ant-row {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
}
.theme-form > .ant-row > .ant-col {
  padding: 0 8px;
}
.theme-form .ant-form-item {
  margin: 0 0 14px;
  padding-bottom: 0 !important;
}
.theme-form .ant-form-item .ant-form-item-label {
  padding: 0px;
}
.theme-form .ant-form-item label {
  color: var(--tertiaryColor);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin: 0 0 6px;
}
.theme-form .ant-form-item input.ant-input {
  padding: 6px 14px;
  font-size: 14px;
  height: 44px;
  background: transparent;
  color: var(--tertiaryColor);
  box-shadow: none;
  border: 1px solid var(--grayColor);
  border-color: var(--grayColor) !important;
}
.theme-form .ant-form-item input.ant-input:hover {
  border-color: var(--tertiaryColor) !important;
}
.theme-form .ant-form-item input.ant-input:focus {
  border-color: var(--tertiaryColor) !important;
}
.theme-form .custom-select-multiple .ant-select-selection {
  box-shadow: none;
  min-height: 44px;
  border-color: var(--grayColor) !important;
}
.theme-form .custom-select-multiple .ant-select-selection:hover {
  border-color: var(--tertiaryColor) !important;
}
.theme-form .custom-select-multiple .ant-select-selection ul .ant-select-selection__choice{
  border: none;
  color: var(--primaryColor);
  background-color: var(--primaryColorAlpha);
}
.theme-form .custom-select-multiple .ant-select-selection ul .ant-select-selection__choice i {
  color: var(--tertiaryColor);
}
.theme-form .button-section {
  justify-content: flex-end;
}
.theme-form .button-section .submitBtn {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.theme-form .button-section .submitBtn:hover {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}
/* Checkbox */
.theme-form .custom-checkbox label {
  display: inline-block;
}
.theme-form .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.theme-form .custom-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.theme-form .custom-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.theme-form .custom-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.theme-form .custom-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primaryColor);
}

/* Dropdown Option Dropdown */
.theme-form .custom-dropdown-part {
  height: 44px;
  color: var(--primaryColor) !important;
  border: 1px solid var(--grayColor);
  box-shadow: none !important;
  font-weight: 500;
  outline: none;
}
.theme-form .custom-dropdown-part:focus,
.theme-form .custom-dropdown-part:hover {
  border: 1px solid var(--tertiaryColor);
}
.custom-dropdown-option {}
.custom-dropdown-option ul {
  padding: 8px 0;
}
.custom-dropdown-option ul li {
  font-size: 14px;
  color: var(--tertiaryColor);
}
.custom-dropdown-option ul li:hover {
  background-color: var(--primaryColorAlpha);
}
/* Select Option Dropdown */
.custom-select-option {}
.custom-select-option ul {
  padding: 8px 0;
}
.custom-select-option ul li {
  font-size: 14px;
  color: var(--tertiaryColor);
}
.custom-select-option ul li.ant-select-dropdown-menu-item-active {
  background-color: var(--primaryColorAlpha) !important;
}
.custom-select-option ul li:hover {
  background-color: var(--primaryColorAlpha) !important;
}
/* EDIT ~ Modal */
.send-mail-modal {
  padding: 0px !important;
}
.send-mail-modal .ant-modal-close {
  max-width: 50px;
  margin: 0px 6px;
  cursor: pointer;
}
.send-mail-modal .ant-modal-header .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--primaryColor);
  text-transform: capitalize;
}
.send-mail-modal .ant-modal-body .ant-form-item-label {
  width: 100%;
}
.send-mail-modal .ant-modal-body .ant-col {
  width: 100%;
  padding: 0px;
}
.send-mail-modal .ant-modal-footer {
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.send-mail-modal .ant-modal-footer > div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
}
.send-mail-modal .ant-modal-footer .ant-btn {
  border-color: var(--grayColor);
  color: var(--tertiaryColor);
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  max-width: 140px;
}
.send-mail-modal .ant-modal-footer .ant-btn:hover {
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}
.send-mail-modal .ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.send-mail-modal .ant-modal-footer .ant-btn.ant-btn-primary:hover {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}

/* Custom ~ TABS */
.custom-tabs {
  margin: 24px 0 0;
}
.custom-tabs .ant-tabs-bar {
  border-bottom: 1px solid var(--grayColor);
  margin: 0 0 8px;
}
.custom-tabs .ant-tabs-tab {
  color: var(--secondaryColor);
  margin: 0 12px 0 0;
  padding: 12px 14px;
}
.custom-tabs .ant-tabs-tab:hover {
  color: var(--tertiaryColor) !important;
}
.custom-tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: var(--primaryColor);
}
.custom-tabs .ant-tabs-ink-bar {
  background-color: var(--primaryColor);
}
.custom-tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0 12px 0 0;
}

/* Button */
button {
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  border-radius: 8px;
  border: 1px solid transparent;
  color: var(--whiteColor);
  background-color: var(--primaryColor);
}

.main-content {
  background-color: '#fff'
}